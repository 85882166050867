import React, {useCallback} from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { loadSlim } from "tsparticles-slim";

const ParticleJS = () => {

    const particlesInit = useCallback(async engine => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            options={{
                background: {
                    color: {
                        value: "#000",
                    },
                },
                fpsLimit: 60,
                particles: {
                    color: {
                        value: ["#c1121f", "#06d6a0"],
                    },
                    links: {
                        color: "#F8F9FA",
                        distance: 400,
                        enable: true,
                        opacity: 0.4,
                        width: 0.5,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        direction: "top-right",
                        enable: true,
                        outMode: "out",
                        random: false,
                        speed: 2,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            value_area: 800,
                        },
                        value: 5,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "triangle",
                    },
                    size: {
                        random: true,
                        value: 10,
                    },
                },
                detectRetina: true,
            }}
        />
    );
};

export default ParticleJS;
