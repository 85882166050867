import {
    Button,
    FormControl,
    Grid, IconButton, InputLabel, MenuItem, Select, Slider,
    Tab,
    Tabs,
    TextField, Typography
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import DOMPurify from "dompurify";
import {Add, Create, PlusOne, Repeat, Warning} from "@mui/icons-material";
import {GlobalContext} from "../../../GlobalContext";

const sxInputFields = {
    '& .MuiInputLabel-root': { color: 'var(--white)' },
    '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'var(--white)' },
        '&:hover fieldset': { borderColor: 'var(--white)' },
        '&.Mui-focused fieldset': { borderColor: 'var(--blue)' },
    },
    '& .MuiInputBase-input': { color: 'var(--white)' }
}

const sxSelectFields = {
    '& .MuiInputLabel-root': { color: 'var(--white)' },
    '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'var(--white)' },
        '&:hover fieldset': { borderColor: 'var(--white)' },
        '&.Mui-focused fieldset': { borderColor: 'var(--blue)' },
    },
    '& .MuiInputBase-input': { color: 'var(--white)' },
    '& .MuiSvgIcon-root': { color: 'var(--white)' },
};

export default function AddCustomerElement (props) {
    const [inputs, setInputs] = useState({percentageWin: 1});
    const [tabValue, setTabValue] = useState(0);
    const [random, setRandom] = useState(Math.floor(Math.random()*100));
    const {updateSnackbarInfo, sendRequest} = useContext(GlobalContext);

    useEffect(() => {}, [props.customers]);

    const onChange = (e, type) => {
        let content = DOMPurify.sanitize(e.target.value);
        setInputs(prev => ({...prev, [type]: content}));
    }

    const onSliderChange = (event, newValue) => {
        setInputs(prev => ({...prev, percentageWin: newValue}));
    };

    const onClick = async (e) => {
        const requiredFields = [
            'name', 'phonenumber', 'email', 'password',
            'street', 'city', 'zipcode', 'iban', 'bic',
            'metatraderPassword', 'metatraderID', 'role', 'percentageWin'
        ];

        const isAllFieldsFilled = requiredFields.every(field => inputs[field]);
        if (!isAllFieldsFilled) {
            updateSnackbarInfo({ msg: "Bitte fülle alle benötigten Angaben aus!", type: "warning" });
            return;
        }

        const cinputs = {...inputs};
        let res = null;

        cinputs['password'] = btoa(inputs.password)

        if (cinputs.addressExtra && cinputs.addressExtra.length <= 1) {
            delete cinputs.addressExtra;
            res = await sendRequest("admin/addc", cinputs);
        }else {
            res = await sendRequest("admin/addc", cinputs);
        }

        if (res.state === "-100") {
            updateSnackbarInfo({ msg: "Leider gibt es ungültige Angaben.", type: "error" });
            return;
        }

        if (res.state === "-115") {
            updateSnackbarInfo({ msg: "Dazu hast du keine Berechtigung!", type: "warning" });
            return;
        }

        if (res.state === "1") {
            updateSnackbarInfo({ msg: `Der Nutzer "${inputs.name}" wurde erfolgreich erstellt!`, type: "success" });
            setInputs({percentageWin: 1});
            setTabValue(0);
            props.updateAdminData();
        }else {
            updateSnackbarInfo({ msg: `Das hat leider nicht funktioniert. Bitte prüfe deine Angaben nochmal!`, type: "error" });
        }

    }

    return (
        <Grid item xs={12}>
                <div style={{width: "100%", height: "100%"}}>
                    <hr style={{color: "var(--white)"}}/>
                    <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)} variant="scrollable" scrollButtons={Object.keys(inputs).length>11}>
                        <Tab label="Persönlich" />
                        <Tab label="Adresse" disabled={!inputs.name || !inputs.phonenumber || !inputs.email || !inputs.password}/>
                        <Tab label="Bank" disabled={!inputs.street || !inputs.city || !inputs.zipcode} />
                        <Tab label="MetaTrader" disabled={!inputs.iban || !inputs.bic}/>
                        <Tab label="System" disabled={!inputs.metatraderPassword || !inputs.metatraderID} />
                        <Tab label="Abschluss" disabled={!inputs.role || !inputs.percentageWin} />
                    </Tabs>
                    <Grid container spacing={2}>

                        <Grid item xs={12}></Grid>

                        {(tabValue === 0) &&
                            <>
                                <Grid item xs={6}>
                                    <TextField
                                        sx={sxInputFields}
                                        required
                                        label="Name"
                                        type="text"
                                        placeholder={"Max Mustermann"}
                                        onChange={(e) => onChange(e, "name")}
                                        value={inputs.name ? inputs.name : ""}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        sx={sxInputFields}
                                        required
                                        type="text"
                                        label="Telefonnummer"
                                        placeholder={"+490000000000"}
                                        onChange={(e) => onChange(e, "phonenumber")}
                                        value={inputs.phonenumber ? inputs.phonenumber : ""}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        sx={sxInputFields}
                                        value={inputs.email ? inputs.email : ""}
                                        placeholder={"test@plutos.bot"}
                                        onChange={(e) => onChange(e, "email")}
                                        label="Email"
                                        type="email"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        sx={sxInputFields}
                                        value={inputs.password ? inputs.password : ""}
                                        placeholder={"SicheresPasswort/123"}
                                        onChange={(e) => onChange(e, "password")}
                                        label="Passwort"
                                        type="password"
                                    />
                                </Grid>
                            </>
                        }

                        {(tabValue === 1) &&
                            <>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        sx={sxInputFields}
                                        value={inputs.street ? inputs.street : ""}
                                        placeholder={"Landstraße 1."}
                                        onChange={(e) => onChange(e, "street")}
                                        label="Straße und Hausnummer"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        sx={sxInputFields}
                                        value={inputs.city ? inputs.city : ""}
                                        placeholder={"Dresden"}
                                        onChange={(e) => onChange(e, "city")}
                                        label="Stadt"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        sx={sxInputFields}
                                        value={inputs.zipcode ? inputs.zipcode : ""}
                                        placeholder={"01123"}
                                        onChange={(e) => onChange(e, "zipcode")}
                                        label="Postleitzahl"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        sx={sxInputFields}
                                        value={inputs.addressExtra ? inputs.addressExtra : ""}
                                        placeholder={"Wohnung 00"}
                                        onChange={(e) => onChange(e, "addressExtra")}
                                        label="Adresszusatz"
                                        type="text"
                                    />
                                </Grid>
                            </>
                        }

                        {(tabValue === 2) &&
                            <>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        sx={sxInputFields}
                                        value={inputs.iban ? inputs.iban : ""}
                                        placeholder={"DE02120300000000202051"}
                                        onChange={(e) => onChange(e, "iban")}
                                        label="IBAN"
                                        type="text"
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        sx={sxInputFields}
                                        value={inputs.bic ? inputs.bic : ""}
                                        placeholder={"AAAABBCC123"}
                                        onChange={(e) => onChange(e, "bic")}
                                        label="BIC"
                                        type="text"
                                    />
                                </Grid>
                            </>
                        }

                        {(tabValue === 3) &&
                            <>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        type="number"
                                        sx={sxInputFields}
                                        label="MetaTrader-ID"
                                        placeholder={"12345678"}
                                        onChange={(e) => onChange(e, "metatraderID")}
                                        value={inputs.metatraderID ? inputs.metatraderID : ""}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type="text"
                                        sx={sxInputFields}
                                        label="MetaTrader-Passwort"
                                        placeholder={"Password/123"}
                                        onChange={(e) => onChange(e, "metatraderPassword")}
                                        value={inputs.metatraderPassword ? inputs.metatraderPassword : ""}
                                    />
                                </Grid>
                            </>
                        }

                        {(tabValue === 4) &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant={"subtitle1"}>Prozentuale Kommission pro Gewinn (%)</Typography>
                                    <Slider
                                        value={inputs.percentageWin}
                                        getAriaValueText={(value) => `${value}%`}
                                        valueLabelDisplay="auto"
                                        onChange={onSliderChange}
                                        step={0.5}
                                        marks
                                        min={1}
                                        max={10}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl sx={sxSelectFields} fullWidth>
                                        <InputLabel id={random+""}>Berechtigungsgruppe</InputLabel>
                                        <Select labelId={random+""}
                                                value={inputs.role ? inputs.role : "none"}
                                                onChange={event => onChange(event, "role")}
                                                MenuProps={{PaperProps: {sx: {bgcolor: 'var(--gray-scale-8)', color: 'var(--white)'}}}}
                                                autoWidth
                                                label="Berechtigungsgruppe">
                                            <MenuItem value={"none"}></MenuItem>
                                            <MenuItem value={"admin"}>Admin</MenuItem>
                                            <MenuItem value={"affiliate"}>Affiliate</MenuItem>

                                            <MenuItem value={"customer"}>Kunde</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {inputs.role && (inputs.role === "admin") &&
                                    <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <IconButton color="error">
                                            <Warning sx={{color: "var(--red)", fontSize: "2rem"}}/>
                                        </IconButton>
                                    </Grid>
                                }

                            </>
                        }

                        {(tabValue === 5) &&
                            <>
                                <Grid item xs={6}>
                                    <Button endIcon={<Repeat/>} onClick={(e) => {setInputs({percentageWin: 1}); setTabValue(0)}} variant={"contained"} color={"warning"} fullWidth>Neustarten</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button endIcon={<Add/>} onClick={onClick} variant={"contained"} color={"success"} fullWidth>Nutzer erstellen</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={"subtitle1"} sx={{fontWeight: 700}}>Hinweis:</Typography>
                                    <Typography variant={"subtitle2"}>Das hinzufügen des Nutzers, beinhaltet <span style={{fontWeight: 700, color: "var(--orange)"}}>nicht</span> die Erstellung der virtuellen Maschine!</Typography>
                                </Grid>
                            </>

                        }

                    </Grid>
                </div>
        </Grid>
    );
}
