import React, { createContext, useState } from 'react';
import {Alert, Snackbar} from "@mui/material";

// Erstellen des Kontexts
const GlobalContext = createContext();

const apiUrl = (!process.env.NODE_ENV || process.env.NODE_ENV === "development") ? "http://localhost:5600/api/v1/" : "https://trabo-trading.com/api/v1/"

const GlobalProvider = ({ children }) => {
    const [snackbarInfo, setSnackbarInfo] = useState({ msg: "", type: "success" });
    const [userData, setUserData] = useState({
        isLoggedIn: false,
        token: null,
        email: null,
        name: null,
        role: null,
        image: null
    });

    const widthBreakpoint = 1000;

    const sendRequest = async (url, data) => {
        const controller = new AbortController();
        const { signal } = controller;

        try {
            const fetchData = async () => {
                const headers = { 'Content-Type': 'application/json' };
                if (userData.isLoggedIn && userData.token) headers['authorization'] = userData.token

                const response = await fetch(`${apiUrl}${url}`, {
                    method: 'POST',
                    credentials: 'include',
                    mode: 'cors',
                    headers: headers,
                    body: data ? JSON.stringify(data) : '{}',
                    signal
                });

                const json = await response.json();
                json.isOk = response.ok && json.state === "1";
                return json;
            };

            const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error('Request timed out')), 1000*10));

            const response = await Promise.race([fetchData(), timeoutPromise]);

            // check for invalid session
            if (response.state === "-103" && userData.isLoggedIn) {
                setUserData({
                    isLoggedIn: false,
                    token: null,
                    name: null,
                    email: null,
                    role: null,
                    image: null
                });
                updateSnackbarInfo({msg: "Sie wurden abgemeldet, da ihre Sitzung nicht mehr gültig ist.", type: "warning"});
                document.cookie = 'connect.sid=; Max-Age=-99999999;';
                setUserData({isLoggedIn: false, token: null, email: null, role: null, image: null, name: null});
                return {state: response.state, data: response.data};
            }

            return response;
        } catch (error) {
            console.error(error)
            if (error.message === 'Request timed out' || error.name === 'AbortError' || error.message === "NetworkError when attempting to fetch resource.") {
                updateSnackbarInfo({msg: "Leider gibt es ein Problem mit der Verbindung.", type: "error"});
                return { state: "-299", message: "problem with connection to api" };
            } else {

                if (!url.endsWith("auth/login") && !url.endsWith("auth/logout") && !url.endsWith("auth/verify")){
                    // TODO: Error Mapping, messages
                    updateSnackbarInfo({msg: "Leider ist ein unerwarteter Fehler aufgetreten!", type: "error"});
                }

                return { state: "-200", message: error.message };
            }
        } finally {
            controller.abort();
        }
    };


    const login = async (emailObj, password) => {
        if (!userData.isLoggedIn) {

            // this happens if the user is already logged in
            if (!password) {
                setUserData({
                    isLoggedIn: true,
                    token: emailObj.data.token,
                    email: emailObj.data.email,
                    role: emailObj.data.role,
                    name: emailObj.data.name,
                    image: null
                });
                updateSnackbarInfo({msg: "Erfolgreich eingeloggt!", type: "success"});
                return true;
            }


            password = btoa(password)
            const res = await sendRequest("auth/login", {email: emailObj, password});
            if (!res || !res.state || res.state==="-200") {
                updateSnackbarInfo({msg: "Ein unerwarteter Fehler ist aufgetreten", type: "error"});
                return false;
            }

            if (res.state === "1") {
                setUserData({
                    isLoggedIn: true,
                    token: res.data.token,
                    email: res.data.email,
                    role: res.data.role,
                    name: res.data.name,
                    image: null
                });
                updateSnackbarInfo({msg: "Erfolgreich eingeloggt!", type: "success"});
                return true;
            }

            return false;
        }
    }


    const logout = async () => {
        if (userData.isLoggedIn) {
            const res = await sendRequest("auth/logout", {});
            if (res.state === "1") {
                document.cookie = 'connect.sid=; Max-Age=-99999999;';
                setUserData({isLoggedIn: false, token: null, email: null, role: null, image: null, name: null});
                updateSnackbarInfo({msg: "Erfolgreich ausgeloggt!", type: "success"});
                document.title = "Trabo » Dein Aktienhändler"
            }
        }
    }


    const updateSnackbarInfo = (data) => {
        if (data && (data.msg !== snackbarInfo.msg || data.type !== snackbarInfo.type)) {
            setSnackbarInfo(data);
        } else if (!data && snackbarInfo.msg !== "") {
            setSnackbarInfo({ msg: "", type: "success" });
        }
    };

    return (
        <GlobalContext.Provider value={{ sendRequest, updateSnackbarInfo, login, logout, userData, widthBreakpoint }}>
            <Snackbar
                open={snackbarInfo.msg.length > 0}
                onClose={(event, reason) => {
                    if (reason === 'clickaway') return;
                    updateSnackbarInfo({msg: "", type: "success"});
                }}
                autoHideDuration={5000}
            >
                <Alert onClose={() => updateSnackbarInfo(null)} severity={snackbarInfo.type}>
                    {snackbarInfo.msg}
                </Alert>
            </Snackbar>

            {children}
        </GlobalContext.Provider>
    );
};


export {GlobalContext, GlobalProvider};
