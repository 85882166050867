import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import React from "react";

export default function OverviewCustomerDialog(props) {


    return (
        <Dialog
            open={props.isOpen}
            sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}
            onClose={(e) => {e.preventDefault();props.setIsOpen(false);}}>

            <DialogTitle>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography variant={"h6"} style={{fontWeight: 700, fontSize: "24px"}}>Detaillierte Übersicht</Typography>
                </div>
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Typography color={"warn"} variant={"h6"}>Noch in arbeit</Typography>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant={"outlined"} color={"primary"} onClick={(e) => props.setIsOpen(false)}>Schließen</Button>
            </DialogActions>

        </Dialog>
    );
}