import InterfacePage from "./components/interfacePage/InterfacePage";
import LandingPage from "./components/landingPage/LandingPage";
import React, {useContext, useEffect, useState} from "react";
import SupportDialog from "./components/menus/SupportDialog";
import NewsDialog from "./components/menus/NewsDialog";
import ParticleJS from "./components/libs/ParticleJS";
import TopMenu from "./components/menus/TopMenu";
import { Box, CssBaseline} from "@mui/material";
import {GlobalContext} from "./GlobalContext";
import {sendBanner} from "./components/utils/consoleLogBanner";

function App() {
    const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
    const [isAdminModeEnabled, setIsAdminModeEnabled] = useState(false);
    const { userData } = useContext(GlobalContext);

    const [isSupportDialogOpen, setIsSupportDialogOpen] = useState(false);
    const [isNewsDialogOpen, setIsNewsDialogOpen] = useState(false);
    const [isFAQDialogOpen, setIsFAQDialogOpen] = useState(false);

    const updateAdminMode = () => {
        if (!userData.isLoggedIn || !userData.token || userData.role !== "owner") return;
        setIsAdminModeEnabled(!isAdminModeEnabled);
    }

    useEffect(() => {
        if (!userData.isLoggedIn || !userData.token) setIsAdminModeEnabled(false);
    }, [userData]);

    useEffect(() => {
        let resizeTimeout;
        let initialWidth = window.innerWidth;
        let initialHeight = window.innerHeight;

        const handleResize = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                const widthDifference = Math.abs(window.innerWidth - initialWidth);
                const heightDifference = Math.abs(window.innerHeight - initialHeight);
                if (widthDifference > 200 || heightDifference > 200) {
                    window.location.reload();
                }
            }, 1000);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            clearTimeout(resizeTimeout);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    sendBanner()

    return (
        <div className="app" style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
            {isNewsDialogOpen && <NewsDialog isOpen={isNewsDialogOpen} setIsOpen={setIsNewsDialogOpen}/>}
            {isSupportDialogOpen && <SupportDialog isOpen={isSupportDialogOpen} setIsOpen={setIsSupportDialogOpen} userData={userData}/>}

            <CssBaseline/>
            <TopMenu userData={userData}
                     setIsNewsDialogOpen={setIsNewsDialogOpen}
                     isNewsDialogOpen={isNewsDialogOpen}
                     setIsSupportDialogOpen={setIsSupportDialogOpen}
                     isSupportDialogOpen={isSupportDialogOpen}
                     loggedIn={userData.isLoggedIn}
                     setIsLoginDialogOpen={setIsLoginDialogOpen}
                     updateAdminMode={updateAdminMode}
                     isAdminModeEnabled={isAdminModeEnabled}/>
            <ParticleJS />
            <Box sx={{flexGrow: 1, padding: '20px', backgroundColor: 'transparent', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: '25px', position: 'relative', zIndex: 1}}>
                <Box sx={{position: 'relative', width: '100%', height: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '400px', borderRadius: '12px', marginTop: '4%'}}>
                    { (userData.isLoggedIn && userData.token) ?
                        <InterfacePage isAdminModeEnabled={isAdminModeEnabled} />
                        :
                        <LandingPage setIsNewsDialogOpen={setIsNewsDialogOpen}
                                     isNewsDialogOpen={isNewsDialogOpen}
                                     setIsSupportDialogOpen={setIsSupportDialogOpen}
                                     isSupportDialogOpen={isSupportDialogOpen}
                                     isLoginDialogOpen={isLoginDialogOpen}
                                     setIsLoginDialogOpen={setIsLoginDialogOpen}/>
                    }
                </Box>
            </Box>
        </div>
    );
}

export default App;
