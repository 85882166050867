import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, FormControl, Grid,
    InputLabel, MenuItem, Select, TextField,
    Typography
} from "@mui/material";
import React, {useContext, useState} from "react";
import DOMPurify from "dompurify";
import {GlobalContext} from "../../GlobalContext";

const sxInputFields = {
    '& .MuiInputLabel-root': { color: 'var(--white)' },
    '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'var(--white)' },
        '&:hover fieldset': { borderColor: 'var(--white)' },
        '&.Mui-focused fieldset': { borderColor: 'var(--blue)' },
    },
    '& .MuiInputBase-input': { color: 'var(--white)' }
}

const sxSelectFields = {
    '& .MuiInputLabel-root': { color: 'var(--white)' },
    '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'var(--white)' },
        '&:hover fieldset': { borderColor: 'var(--white)' },
        '&.Mui-focused fieldset': { borderColor: 'var(--blue)' },
    },
    '& .MuiInputBase-input': { color: 'var(--white)' },
    '& .MuiSvgIcon-root': { color: 'var(--white)' },
};


export default function SupportDialog(props) {
    const [reason, setReason] = React.useState('');
    const [inputs, setInputs] = useState(props.userData.isLoggedIn ? {name: props.userData.name, email: props.userData.email} : {});

    const {sendRequest, updateSnackbarInfo, logout} = useContext(GlobalContext);

    const handleReasonChange = (event) => setReason(DOMPurify.sanitize(event.target.value));

    const handleInputChange = (event, type) => {
        let content = DOMPurify.sanitize(event.target.value);
        if (type === "message" && content.length > 1024) return;
        setInputs(prev => ({...prev, [type]: content}));
    }

    const submitSupportTicket = async (e) => {
        e.preventDefault();
        const requiredFields = ["email", "name", "message"];
        const isAllFieldsFilled = requiredFields.every(field => inputs[field]) && reason;
        if (!isAllFieldsFilled) {
            updateSnackbarInfo({ msg: "Bitte fülle alle benötigten Angaben aus!", type: "warning" });
            return;
        }

        const res = await sendRequest("support", {email: inputs.email, name: inputs.name, message: btoa(inputs.message), reason: reason});

        if (res.state  === "-100") {
            updateSnackbarInfo({msg: "Bitte überprüfe deine Angaben nochmal!", type: "error"});
            return;
        }

        if (res.state  === "-121") {
            updateSnackbarInfo({msg: "Dein angegebener Grund konnte nicht verifiziert werden. Bitte probiere es nochmal.", type: "error"});
            return;
        }

        if (res.state === "-120") {
            updateSnackbarInfo({msg: "Leider ist ein Problem mit deiner Sitzung aufgetreten. Bitte entschuldige die Unannehmlichkeiten!", type: "error"});
            props.setIsOpen(false);
            setInputs({});
            logout();
            return;
        }

        if (res.state === "1") {
            updateSnackbarInfo({msg: "Vielen Dank, deine Anfrage wurde erfolgreich übermittelt!", type: "success"});
            props.setIsOpen(false);
            setInputs({});
            return;
        }

    }

    return(
        <>
            <Dialog
                open={props.isOpen}
                sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}
                onClose={(e) => {e.preventDefault();props.setIsOpen(false);}}>

                <DialogTitle>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant={"h6"} style={{fontWeight: 700, fontSize: "24px"}}>Support</Typography>
                    </div>
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle1"}>Bei Fragen oder Problemen wende dich gerne immer direkt an uns! <br/> Wir werden dir innerhalb von <span style={{fontWeight: 700}}>einem</span> Tag antworten.</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {props.userData.isLoggedIn ?
                                <TextField sx={{...sxInputFields, border: "1px solid var(--blue)", borderRadius: "4px"}}
                                           disabled
                                           fullWidth
                                           label={props.userData.name}
                                           type="text"
                                           onChange={(e) => e.preventDefault()}
                                           value={""}/> :
                                <TextField sx={sxInputFields} required fullWidth label="Name" type="text" placeholder={"Max Mustermann"} onChange={(e) => handleInputChange(e, "name")} value={inputs.name ? inputs.name : ""}/>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            {props.userData.isLoggedIn ?
                                <TextField sx={{...sxInputFields, border: "1px solid var(--blue)", borderRadius: "4px"}}
                                           disabled
                                           fullWidth
                                           label={props.userData.email}
                                           type="text"
                                           onChange={(e) => e.preventDefault()}
                                           value={""}/> :
                                <TextField sx={sxInputFields}
                                           required
                                           fullWidth
                                           label="Email"
                                           type="text"
                                           placeholder={"muster@email.com"}
                                           onChange={(e) => handleInputChange(e, "email")}
                                           value={inputs.email ? inputs.email : ""}/>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required sx={sxSelectFields} fullWidth>
                                <InputLabel>Grund</InputLabel>
                                <Select
                                    MenuProps={{PaperProps: {sx: {bgcolor: 'var(--gray-scale-8)', color: 'var(--white)'}}}}
                                    value={reason} label="Grund" onChange={handleReasonChange}>
                                    <MenuItem value={0}>Technisches Problem</MenuItem>
                                    <MenuItem value={1}>Anregungen oder Kritik</MenuItem>
                                    <MenuItem value={2}>Allgemeine Anfragen</MenuItem>
                                    <MenuItem value={3}>Fragen zur Bedienung</MenuItem>
                                    <MenuItem value={4}>Fragen zu IC Markets</MenuItem>
                                    <MenuItem value={5}>Sicherheits- und Datenschutzfragen</MenuItem>
                                    <MenuItem value={6}>Finanzen / Buchhaltung / Steuern</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={sxInputFields}
                                required
                                fullWidth
                                label="Nachricht"
                                rows={6}
                                multiline
                                type="text"
                                placeholder={"..."}
                                onChange={(e) => handleInputChange(e, "message")}
                                value={inputs.message ? inputs.message : ""}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button variant={"outlined"} color={"primary"} onClick={(e) => props.setIsOpen(false)}>Schließen</Button>

                    {
                        ((props.userData.name || inputs.name) &&
                            (props.userData.email || inputs.email) &&
                            reason && (inputs.message && inputs.message.length>3)) &&
                        <Button variant={"contained"} color={"success"} onClick={submitSupportTicket}>Absenden</Button>
                    }

                </DialogActions>

            </Dialog>
        </>
    )

}