
export const validateInput = (content, type) => {
    if (type === "email") {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(content) && content.includes("@") && (content.split("@")[1].length > 4);
    } else if (type === "password") {
        return content.length>2 && content.length<=128;
    }else {
        return false;
    }
}