import { Grid } from "@mui/material";
import InterfacePlotlyChart from "../libs/InterfacePlotlyChart";
import {useEffect, useState} from "react";

export default function ChartElement(props) {
    const [useData, setData] = useState(null);

    const init = () => {
        if (useData === null) {
            const data = {
                x: [],
                y: [],
                type: 'scatter',
                mode: 'lines',
                marker: { color: '#118ab2'},
                line : {
                    width: 5,
                    shape: 'spline'
                }
            }
            for (const item of props.chart.data) {
                data.x.push(item.date);
                data.y.push(item.averageEquity);
            }
            setData([data]);
        }
    }

    useEffect(() => {
        init()
    }, []);

    return (
        <Grid item xs={12}>
            {!props.chart.err && (
                <div style={{width: "100%", height: "100%"}}>
                    <InterfacePlotlyChart data={useData} />
                </div>
            )}
        </Grid>
    );
}