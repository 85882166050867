import {Box, Grid, Typography} from "@mui/material";
import { styled } from '@mui/material/styles';
import {useEffect} from "react";

const elementStyles = {
    height: "200px",
    borderRadius: "12px",
    border: "1px solid white",
    boxShadow: "0 4px 6px rgba(0, 150, 250, 0.2), 0 1px 3px rgba(0, 150, 250, 0.15)",
}

const StateDoteGood = styled(Box)(({ theme }) => ({
    width: '12px',
    height: '12px',
    backgroundColor: 'var(--green)',
    borderRadius: '50%',
    display: 'inline-block',
    boxShadow: `0 0 0 2px var(--green)`,
}));

const StateDoteWarn = styled(Box)(({ theme }) => ({
    width: '12px',
    height: '12px',
    backgroundColor: 'var(--orange)',
    borderRadius: '50%',
    display: 'inline-block',
    boxShadow: `0 0 0 2px var(--orange)`,
}));

const StateDoteError = styled(Box)(({ theme }) => ({
    width: '12px',
    height: '12px',
    backgroundColor: 'var(--red)',
    borderRadius: '50%',
    display: 'inline-block',
    boxShadow: `0 0 0 2px var(--red)`,
}));


function VMState(props) {
    const customerDatetime = new Date(props.time);
    customerDatetime.setHours(customerDatetime.getHours() -2);
    const currentTime = new Date();

    if ((currentTime.getTime() - customerDatetime.getTime()) > 120000) {
        return <div style={{paddingLeft: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px"}}>
            <Typography variant={"subtitle1"} sx={{fontSize: "22px"}}>{props.metatraderID}</Typography>
            <StateDoteError/>
        </div>
    }else if ((currentTime.getTime() - customerDatetime.getTime()) > 60000) {
        return <div style={{paddingLeft: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px"}}>
            <Typography variant={"subtitle1"} sx={{fontSize: "22px"}}>{props.metatraderID}</Typography>
            <StateDoteWarn/>
        </div>
    }else {
        return <div style={{paddingLeft: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px"}}>
            <Typography variant={"subtitle1"} sx={{fontSize: "22px"}}>{props.metatraderID}</Typography>
            <StateDoteGood/>
        </div>
    }


}


export default function OverviewSystemElement(props) {
    useEffect(() => {
        const intervalId = setInterval(() => {
            props.updateSystemInfo();
        }, 10000);
        return () => clearInterval(intervalId);
    }, [props.systemInfo]);


    return (
        <div style={{width: "100%", marginLeft: "25px"}}>
            <hr style={{color: "var(--white)", marginBottom: "15px"}}/>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <div style={elementStyles}>
                        <Typography variant={"h6"} sx={{
                            textAlign: "center",
                            fontWeight: 700,
                            marginBottom: "10px",
                            textDecoration: "2px underline var(--indigo)"}}>
                            Übersicht der VM's
                        </Typography>
                        <div style={{overflow: "auto", height: "150px"}}>
                            {props.systemInfo.data.lastCustomerDataEntries.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()).map((item, key) =>
                                <VMState key={`vmCustomerEntry-${key}`} metatraderID={item.metatraderID} time={item.time}/>
                            )}
                        </div>
                    </div>

                </Grid>

                <Grid item xs={4}>
                    <div style={elementStyles}>
                        <Typography variant={"h6"} sx={{textAlign: "center", fontWeight: 700, marginBottom: "25px", textDecoration: "2px underline var(--blue)",}}>
                            Aktuelle Datennutzung (Frontend)
                        </Typography>

                        <div style={{textAlign: "center"}}>
                            <Typography variant={"h6"}
                                        sx={{fontSize: "30px"}}>
                                <span style={{color: "var(--yellow)", fontWeight: 700}}>In:</span> {Math.floor(props.systemInfo.data.hetzner.traffic.in / 1024 / 1024)} MB
                            </Typography>
                        </div>

                        <div style={{textAlign: "center"}}>
                            <Typography variant={"h6"} sx={{fontSize: "30px"}}>
                                <span style={{color: "var(--orange)", fontWeight: 700}}>Out:</span> {Math.floor(props.systemInfo.data.hetzner.traffic.out / 1024 / 1024)} MB
                            </Typography>
                        </div>

                    </div>

                </Grid>

                <Grid item xs={4}>
                    <div style={elementStyles}>
                        <Typography variant={"h6"} sx={{textAlign: "center", fontWeight: 700, marginBottom: "10px", textDecoration: "2px underline var(--green)"}}>
                            Datenbank - Überblick
                        </Typography>

                        <div style={{marginBottom: "10px"}}>
                            <div style={{paddingLeft: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px"}}>
                                <Typography variant={"subtitle1"} style={{fontWeight: 700}}>Verbindungen </Typography>
                                <Typography variant={"subtitle1"}>
                                    Aktiv: <span
                                    style={{color: "var(--green)"}}>{props.systemInfo.data.mongo.connections.active}</span>,
                                    Aktuell: <span
                                    style={{color: "var(--green)"}}>{props.systemInfo.data.mongo.connections.current}</span>,
                                    Verfügbar: <span
                                    style={{color: "var(--green)"}}>{props.systemInfo.data.mongo.connections.available}</span>
                                </Typography>
                            </div>
                        </div>

                        <div style={{marginBottom: "10px"}}>
                            <div style={{paddingLeft: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px"}}>
                                <Typography variant={"subtitle1"} style={{fontWeight: 700}}>RAM / CPU </Typography>
                                <Typography variant={"subtitle1"}>
                                    Virtuell: <span
                                    style={{color: "var(--green)"}}>{Math.floor(props.systemInfo.data.mongo.ram.virtual / 1024)}GB</span>,
                                    Reserviert: <span
                                    style={{color: "var(--green)"}}>{Math.floor(props.systemInfo.data.mongo.ram.resident / 1024)}GB</span>,
                                    Threads: <span
                                    style={{color: "var(--green)"}}>{props.systemInfo.data.mongo.cpu.threads}</span>
                                </Typography>
                            </div>
                        </div>

                        <div style={{marginBottom: "10px"}}>
                            <div style={{paddingLeft: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px"}}>
                                <Typography variant={"subtitle1"} style={{fontWeight: 700}}>Uptime </Typography>
                                <Typography variant={"subtitle1"}>
                                    <span
                                        style={{color: "var(--green)"}}>{props.systemInfo.data.mongo.state.uptime}</span>
                                </Typography>
                            </div>
                        </div>

                        <div>
                            <div style={{paddingLeft: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px"}}>
                                <Typography variant={"subtitle1"} style={{fontWeight: 700}}>Erkannte
                                    Fehler </Typography>
                                <Typography variant={"subtitle1"}>
                                    <span style={{color: props.systemInfo.data.mongo.state.errors ? "var(--red)" : "var(--green)"}}>{props.systemInfo.data.mongo.state.errors ? "Ja" : "Nein"}</span>
                                </Typography>
                            </div>
                        </div>

                    </div>
                </Grid>

            </Grid>
        </div>
    )

}