import React, {useState, useEffect, useContext} from 'react';
import {AppBar, Box, Button, Grid, IconButton, Toolbar, Typography} from '@mui/material';
import {AccountCircle} from "@mui/icons-material";
import ProfileAvatar from "../libs/ProfileAvatar";
import {GlobalContext} from "../../GlobalContext";

const TopMenu = (props) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const {logout} = useContext(GlobalContext);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [props.isAdminModeEnabled]);

    return (
        <>
            <AppBar position="fixed" style={{
                backdropFilter: 'blur(2px)',
                WebkitBackdropFilter: 'blur(4px)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                transition: 'background-color 0.3s ease',
            }}>
                <Toolbar>
                    <Grid container alignItems="center">
                        {/* Left section */}
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" component="div" color="primary" sx={{ fontWeight: 700, fontSize: { xs: 20, md: 26 } }}>
                                <span className={props.loggedIn ? "" : "hover-text"}>Trabo-Trading</span>
                            </Typography>
                        </Grid>

                        {/* Center section with text buttons */}
                        <Grid item xs={12} md={4} container justifyContent="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                <Button color="inherit" onClick={() => props.setIsNewsDialogOpen(!props.isNewsDialogOpen)}>
                                    <Typography variant={"subtitle2"}><span className={"hover-underline"}>News</span></Typography>
                                </Button>
                                <Button color="inherit" onClick={(e) => props.setIsSupportDialogOpen(!props.isSupportDialogOpen)}>
                                    <Typography variant={"subtitle2"}><span className={"hover-underline"}>Support</span></Typography>
                                </Button>
                                <Button color="inherit">
                                    <Typography variant={"subtitle2"}><span className={"hover-underline"}>FAQ</span></Typography>
                                </Button>
                            </Box>
                        </Grid>

                        {/* Right section */}
                        <Grid item xs={6} md={4} container justifyContent="flex-end">

                            {props.loggedIn && props.userData && props.userData.role==="owner" &&
                                <Box sx={{display: {xs: 'none', sm: 'block'}, '& button': {m: 1}}}>
                                    <div style={{marginRight: "50px"}}>
                                        <Button onClick={(e) => props.updateAdminMode()}
                                                variant="outlined"
                                                size="medium"
                                                color={props.isAdminModeEnabled ? "success" : "error"}>Admin-Mode</Button>
                                    </div>
                                </Box>
                            }

                            <IconButton color="inherit">
                                {(props.loggedIn && props.userData)
                                    ?
                                    <ProfileAvatar email={props.userData.email}/>
                                    :
                                    <AccountCircle/>
                                }
                            </IconButton>

                            <Box sx={{'& button': {m: 1}}}>
                                {(props.loggedIn && props.userData) ?
                                    <Button onClick={(e) => logout()} variant="outlined" size="small" color="inherit">Abmelden</Button>
                                    :
                                    <Button onClick={(e) => props.setIsLoginDialogOpen(true)} variant="outlined" size="small" color="inherit">Anmelden</Button>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default TopMenu;
