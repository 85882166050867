import RemoveCustomerElement from "./admin/RemoveCustomerElement";
import ModifyCustomerElement from "./admin/ModifyCustomerElement";
import PersonalOptionsElement from "./PersonalOptionsElement";
import AddCustomerElement from "./admin/AddCustomerElement";
import {useContext, useEffect, useState} from "react";
import QuickStatsElement from "./QuickStatsElement";
import {GlobalContext} from "../../GlobalContext";
import InterfaceElement from "./InterfaceElement";
import BalanceElement from "./BalanceElement";
import HistoryElement from "./HistoryElement";
import ChartElement from "./ChartElement";
import Footer from "../menus/Footer";
import {Grid} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import OverviewSystemElement from "./admin/OverviewSystemElement";

export default function InterfacePage(props) {
    const [balanceData, setBalanceData] = useState({ isLoaded: false, data: [], err: null });
    const [chartData, setChartData] = useState({ isLoaded: false, data: [], err: null });
    const [historyData, setHistoryData] = useState({ isLoaded: false, data: [], err: null });
    const [settingsData, setSettingsData] = useState({ isLoaded: false, data: [], err: null });
    const [counter, setCounter] = useState(0);

    // admin mode
    const [customersData, setCustomersData] = useState({isLoaded: false, data: [], err: null});
    const [systemInfo, setSystemInfo] = useState({isLoaded: false, data: [], err: null});

    // load states
    const [isAdminModeLoaded, setIsAdminModeLoaded] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const { userData, sendRequest, logout } = useContext(GlobalContext);

    useEffect(() => {
        const init = async () => {
            if (!historyData.isLoaded) loadAPIData("customers/history", setHistoryData);
            loadAPIData("customers/balance", setBalanceData);
            loadAPIData("customers/chart", setChartData);
            loadAPIData("customers/settings", setSettingsData);
            setIsLoaded(true);
        };

        const initAdminMode  = async () => {
            loadAPIData("admin/getc", setCustomersData);
            loadAPIData("admin/systeminfo", setSystemInfo);
            setIsAdminModeLoaded(true);
        }

        if (!isLoaded) init();
        if (!isAdminModeLoaded && props.isAdminModeEnabled && (userData.role === "owner" || userData.role === "admin")){
            initAdminMode();
        }

    }, [customersData, userData, props.isAdminModeEnabled]);

    useEffect(() => {
        const token = userData.token;
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);
        const remainingTime = decodedToken.exp - currentTime;

        if (remainingTime <= 0) {
            document.title = "Trabo » Dein Aktienhändler";
            logout();
            return;
        }

        const intervalId = setInterval(() => {
            const currentTime = Math.floor(Date.now() / 1000);
            const remainingTime = decodedToken.exp - currentTime;

            if (remainingTime >= 0) {
                const minutes = Math.floor(remainingTime / 60);
                const seconds = remainingTime % 60;
                document.title = `Trabo » Angemeldet für ${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
            } else {
                clearInterval(intervalId);
                document.title = "Trabo » Dein Aktienhändler";
                logout();
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [userData.token, logout]);

    const loadAPIData = async (category, setData) => {
        const res = await sendRequest(`${category}`, {});

        if (res.state === "1") {
            setData({ isLoaded: true, data: res.data, err: false });
        }else {
            setData({ isLoaded: true, data: [], err: "Die Daten konnten nicht geladen werden" });
        }
    };

    const updateBalanceData = async (e) => loadAPIData("customers/balance", setBalanceData)

    const updateCustomersAdminData = async (e) => loadAPIData("admin/getc", setCustomersData)

    const updateSystemInfo = async (e) => loadAPIData("admin/systeminfo", setSystemInfo)


    return (
        <Grid container spacing={1} style={{ height: '100%', marginTop: "50px" }}>

                {props.isAdminModeEnabled ?
                    <>
                        <InterfaceElement title="System-Übersicht" xs={12} md={12} data={systemInfo} children={<OverviewSystemElement systemInfo={systemInfo} updateSystemInfo={updateSystemInfo} />} updateSystemInfo={updateSystemInfo} color={"var(--green)"} />
                        <InterfaceElement title="Kunden hinzufügen" xs={6} md={4} data={customersData} children={<AddCustomerElement customers={customersData} updateAdminData={updateCustomersAdminData} />} updateAdminData={updateCustomersAdminData} color={"var(--green)"}/>
                        <InterfaceElement title="Kunden bearbeiten" xs={6} md={4} data={customersData} children={<ModifyCustomerElement customers={customersData} updateAdminData={updateCustomersAdminData} />} updateAdminData={updateCustomersAdminData} color={"var(--green)"} />
                        <InterfaceElement title="Kunden entfernen" xs={6} md={4} data={customersData} children={<RemoveCustomerElement customers={customersData} updateAdminData={updateCustomersAdminData} />} updateAdminData={updateCustomersAdminData} color={"var(--green)"}/>
                        {/*<InterfaceElement title="TakeProfit anpassen" xs={6} md={4} data={[]} children={<></>} color={"var(--orange)"} />
                        <InterfaceElement title="RSI anpassen" xs={6} md={4} data={[]} children={<></>} color={"var(--orange)"} />
                        <InterfaceElement title="Bots pausieren" xs={6} md={4} data={[]} children={<></>} color={"var(--red)"} />
                        <InterfaceElement title="Wartungs-Modus aktivieren" xs={6} md={4} data={[]} children={<></>} color={"var(--blue)"} />
                        <InterfaceElement title="News aktualisieren" xs={6} md={8} data={[]} children={<></>} color={"var(--blue)"} />**/}
                    </>
                    :
                    <>
                        <InterfaceElement title="Konto" xs={12} md={4} data={balanceData} children={<BalanceElement updateBalance={updateBalanceData} balance={balanceData}/>} color={"var(--green)"} />
                        <InterfaceElement title="Quick-Statistik" xs={12} md={4} data={balanceData} children={<QuickStatsElement balance={balanceData}/>} color={"var(--red)"} />
                        <InterfaceElement title="Monatsübersicht" xs={12} md={4} data={chartData} children={<ChartElement chart={chartData}/>} color={"var(--indigo)"} />
                        <InterfaceElement title="Kontoverlauf" xs={12} md={8} data={historyData} children={<HistoryElement history={historyData}/>} color={"var(--blue)"} />
                        <InterfaceElement title="Einstellungen" xs={12} md={4} data={settingsData} children={<PersonalOptionsElement settings={settingsData}/>} color={"var(--yellow)"} />
                        <Footer isElementVisible={false}/>
                    </>
                }
        </Grid>
    )
}