import {
    Grid, TextField, Autocomplete, Paper, Switch, Typography, Checkbox, Button, IconButton
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {styled} from "@mui/styles";
import {Warning} from "@mui/icons-material";
import {GlobalContext} from "../../../GlobalContext";

const sxInputFields = {
    '& .MuiInputLabel-root': { color: 'var(--white)' },
    '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'var(--white)' },
        '&:hover fieldset': { borderColor: 'var(--white)' },
        '&.Mui-focused fieldset': { borderColor: 'var(--blue)' },
    },
    '& .MuiInputBase-input': { color: 'var(--white)' }
};

// Angepasster Paper-Komponente für das Dropdown-Menü
const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: 'var(--gray-scale-8)',
    color: 'var(--white)',
    borderRadius: 4,
    boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
}));

export default function RemoveCustomerElement(props) {
    const [isFullDeleteSelect, setIsFullDeleteSelect] = useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(null);

    const {updateSnackbarInfo, sendRequest} = useContext(GlobalContext);

    const init = () => {
        const newOptions = props.customers.data.map((item, key) => `${item.email} (${item.information.name})`);
        setOptions(newOptions);
    };

    useEffect(() => {
        init();
    }, [props.customers]);

    const onClickDelete = async (e) => {
        if (!value) return;
        const rawValue = value.split(" ");
        const email = rawValue[0];

        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!regex.test(email)) {
            updateSnackbarInfo({msg: "Bitte prüfe die angegebene Email Adresse!", type: "error"})
            return;
        }

        const res = await sendRequest("admin/removec", {email, full: isFullDeleteSelect});
        if (res.state === "-115") {
            updateSnackbarInfo({ msg: "Dazu hast du keine Berechtigung!", type: "warning" });
            return;
        }

        if (res.state === "-117") {
            updateSnackbarInfo({ msg: "Ein unerwarteter interner Fehler ist aufgetreten!", type: "warning" });
            return;
        }

        if (res.state === "1") {
            updateSnackbarInfo({
                msg: isFullDeleteSelect ? `Alle Daten des Nutzers ${email} wurden erfolgreich gelöscht.` : `Die Nutzerdaten von ${email} wurden erfolgreich gelöscht.`,
                type: "success"
            });
            setValue(null);
            setIsFullDeleteSelect(false);
            props.updateAdminData();
        }else {
            updateSnackbarInfo({msg: "Ein unerwarteter Fehler ist aufgetreten!", type: "error"});
            setValue(null);
            return;

        }
    }

    return (
        <Grid item xs={12}>
            <div style={{ width: "100%", height: "100%"}}>
                <hr style={{ color: "var(--white)" }} />
                <Grid container spacing={2} sx={{marginTop: "10px"}}>
                    <Grid item xs={12}>
                        <Autocomplete
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            options={options}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField{...params} label="Wähle einen Nutzer" variant="outlined" sx={sxInputFields}/>
                            )}
                            PaperComponent={(props) => <StyledPaper {...props} />}
                            ListboxProps={{
                                sx: {
                                    '& .MuiAutocomplete-option': {
                                        color: 'var(--dropdown-text)',
                                        '&[data-focus="true"]': {
                                            backgroundColor: 'var(--dropdown-hover-background)',
                                            color: 'var(--dropdown-hover-text)',
                                        },
                                    },
                                },
                            }}
                        />
                    </Grid>

                    {value &&
                        <>
                            <Grid item xs={6}>
                                <div style={{ display: "flex", justifyContent: "left", alignItems: "center", textAlign: "left", fontSize: "20px", marginLeft: "2px", marginTop: "10px", height: "35px" }}>
                                    <Typography variant="subtitle1" style={{ marginRight: "8px" }}>Alle Daten löschen</Typography>
                                    <Checkbox checked={isFullDeleteSelect} onChange={(e) => setIsFullDeleteSelect(e.target.checked)} size="medium"/>
                                    {isFullDeleteSelect && <IconButton color="error"><Warning sx={{color: "var(--red)", fontSize: "2rem"}}/></IconButton>}
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div style={{display: "flex", justifyContent: "right", alignItems: "right", textAlign: "right", fontSize: "20px", marginLeft: "2px", marginTop: "10px"}}>
                                    <Button variant={"contained"} color={"error"} onClick={onClickDelete}>Löschen</Button>
                                </div>
                            </Grid>
                        </>
                    }
                </Grid>
            </div>
        </Grid>
    );
}
