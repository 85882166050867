import React, {useContext, useEffect, useState} from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import {GlobalContext} from "../../GlobalContext";

const sxInputFields = {
    '& .MuiInputLabel-root': { color: 'var(--white)' },
    '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'var(--white)' },
        '&:hover fieldset': { borderColor: 'var(--white)' },
        '&.Mui-focused fieldset': { borderColor: 'var(--blue)' },
    },
    '& .MuiInputBase-input': { color: 'var(--white)' }
};

export default function PasswordChangeDialog(props) {
    const [inputs, setInputs] = useState({password: '', newPassword: '', passwordConfirmation: '',});
    const [errors, setErrors] = useState({password: false, newPassword: false, passwordConfirmation: false,});

    const {updateSnackbarInfo, sendRequest} = useContext(GlobalContext);

    const checkIfSessionIsStillValid = async () => {
        const res = await sendRequest("auth/verify", {});
        if (res.state !== "1") {
            setInputs({password: '', newPassword: '', passwordConfirmation: '',});
            setErrors({password: false, newPassword: false, passwordConfirmation: false,});
            props.setIsOpen(false);
        }
    }

    useEffect(() => {
        checkIfSessionIsStillValid();
    }, []);


    const isValidPassword = (pwd) => {
        return inputs[pwd] && inputs[pwd].length >= 16 && inputs[pwd].length <= 128;
    };

    const onChange = (e, type) => {
        const content = DOMPurify.sanitize(e.target.value);
        setInputs((prev) => ({ ...prev, [type]: content }));
    };

    const onBlur = (type) => {
        setErrors((prev) => ({
            ...prev,
            [type]: !isValidPassword(type) || (type === 'passwordConfirmation' && inputs.passwordConfirmation !== inputs.newPassword),
        }));
    };

    const handleClose = () => {
        props.setIsOpen(false);
        setInputs({
            password: '',
            newPassword: '',
            passwordConfirmation: '',
        });
        setErrors({
            password: false,
            newPassword: false,
            passwordConfirmation: false,
        });
    };

    const onClick = async () => {
        if (isValidPassword("password") &&
            isValidPassword("newPassword") &&
            isValidPassword("passwordConfirmation") &&
            inputs.newPassword === inputs.passwordConfirmation) {

            const res = await sendRequest(`customers/changepw`, {
                password: btoa(inputs.password),
                newPassword: btoa(inputs.newPassword)
            });

            if (res.state === "-211") {
                updateSnackbarInfo({msg: "Dein aktuelles Passwort ist falsch. Dein Passwort wurde nicht geändert!", type: "error"});
                return;
            }
            if (res.state === "-212") {
                updateSnackbarInfo({msg: "Ein interner Fehler ist aufgetreten. Dein Passwort wurde nicht geändert!", type: "error"});
                return;
            }
            if (res.state !== "1") {
                updateSnackbarInfo({msg: "Ein unerwarteter Fehler ist aufgetreten. Sollte das Problem weiterhin bestehen, melde dich gerne beim Support!", type: "error"});
                handleClose();
                return;
            }

            updateSnackbarInfo({msg: "Dein Passwort wurde erfolgreich aktualisiert.", type: "success"});
            handleClose();
        }
    };

    return (
        <Dialog open={props.isOpen} onClose={handleClose}>
            <DialogTitle>Ändere dein Passwort</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DialogContentText>
                            <Typography variant="subtitle2"></Typography>
                            <Typography variant="subtitle2">
                                Wir tun unser Bestes, um deine Sicherheit zu gewährleisten, aber dazu brauchen wir deine Mithilfe!<br/>
                                <span style={{fontWeight: 700, color: "var(--orange)" }}>Benutze bitte ein Passwort mit: mindestens 16 Zeichen, Sonderzeichen sowie einer Kombination aus Zahlen und Buchstaben.</span>
                            </Typography>
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            required
                            error={errors.password}
                            fullWidth
                            type="password"
                            sx={sxInputFields}
                            label="Ihr aktuelles Passwort"
                            placeholder="Sicheres/Passwort@123"
                            onChange={(e) => onChange(e, "password")}
                            onBlur={() => onBlur("password")}
                            value={inputs.password}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            error={errors.newPassword}
                            fullWidth
                            type="password"
                            sx={sxInputFields}
                            label="Dein neues Passwort"
                            placeholder="Neues/Sicheres@Passw0rt"
                            onChange={(e) => onChange(e, "newPassword")}
                            onBlur={() => onBlur("newPassword")}
                            value={inputs.newPassword}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            error={errors.passwordConfirmation}
                            fullWidth
                            type="password"
                            sx={sxInputFields}
                            label="Bestätige dein Passwort"
                            placeholder="Neues/Sicheres@Passw0rt"
                            onChange={(e) => onChange(e, "passwordConfirmation")}
                            onBlur={() => onBlur("passwordConfirmation")}
                            value={inputs.passwordConfirmation}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Abbrechen</Button>
                <Button
                    onClick={onClick}
                    disabled={
                        !(isValidPassword("password") &&
                            isValidPassword("passwordConfirmation") &&
                            isValidPassword("newPassword") &&
                            inputs.newPassword === inputs.passwordConfirmation)
                    }
                    autoFocus
                >
                    Aktualisieren
                </Button>
            </DialogActions>
        </Dialog>
    );
}
