import React, {useEffect, useRef, useState} from 'react';
import {Menu, MenuItem, Typography} from "@mui/material";
import PasswordChangeDialog from "../interfacePage/PasswordChangeDialog";

const sxMenuStyles = {
    '& .MuiPaper-root': {
        backgroundColor: '#000',
        color: 'var(--white)',
        border: "1px solid var(--white)"
    },
    '& .MuiMenuItem-root': {
        color: 'var(--white)',
        '&:hover': {
            color: 'var(--blue)',
        },
    },
    transform: "translate(-40px, 5px)"
};

const ProfileAvatar = ({ email }) => {
    const canvasRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isPasswordResetOpen, setIsPasswordResetOpen] = useState(false);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const onClick = (e, type) => {
        e.preventDefault();
        setAnchorEl(null);

        if (type === "passwordReset") {
            setIsPasswordResetOpen(true);
        }

        if (type === "accountDeactivate") {
        }
    }

    const getProfileInitials = (email) => {
        let initials = "";
        const emailPrefix = email.split("@")[0];
        if (!emailPrefix.includes(".")) {
            initials = emailPrefix.slice(0,1);
            return initials.toUpperCase();
        }
        const emailSplitted = emailPrefix.split(".");
        return `${emailSplitted[0].slice(0,1).toUpperCase()}${emailSplitted[1].slice(0,1).toUpperCase()}`;
    }

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = 24;
        canvas.height = 24;

        ctx.fillStyle = anchorEl ? '#118ab2' : '#fff';
        ctx.font = 'bold 18px Montserrat';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(getProfileInitials(email), canvas.width / 2, canvas.height / 2);
    }, [anchorEl, email]);

    return (
        <>
            {isPasswordResetOpen && <PasswordChangeDialog isOpen={isPasswordResetOpen} setIsOpen={setIsPasswordResetOpen}/>}

            <canvas ref={canvasRef} onClick={handleOpen}/>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} sx={sxMenuStyles}>
                <MenuItem sx={{ height: 25, textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center"}}
                          onClick={(e) => onClick(e, "passwordReset")}>
                    Passwort ändern
                </MenuItem>

                <hr style={{color: "var(--white)"}} />

                <MenuItem sx={{ height: 15, textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center"}}
                          onClick={(e) => onClick(e, "accountDeactivate")}>
                    <Typography variant={"subtitle2"} color={"error"} sx={{fontWeight: 700}}>Konto deaktivieren</Typography>
                </MenuItem>
            </Menu>
        </>
    );
};

export default ProfileAvatar;
