import {Box, Button, Fade, Grid, IconButton, Slide, Typography} from "@mui/material";
import TradingViewWidget from "../libs/TradingViewWidget";
import FadeInGridElement from "./FadeInGridElement";
import {AccountCircle, HelpCenter, Newspaper, QuestionAnswer, Send, Support} from "@mui/icons-material";
import {useContext, useState} from "react";
import InfoBox from "./InfoBox";
import LoginDialog from "../login/LoginDialog";
import Footer from "../menus/Footer";
import {GlobalContext} from "../../GlobalContext";

const LargeRoundIconButton = ({ icon, label }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton
                sx={{
                    width: 80,
                    height: 80,
                    backgroundColor: 'rgba(0,0,0,0.1)',
                    '&:active': {
                        transition: "0.1s ease-in",
                        transform: 'translateY(3px)'
                    },
                    borderRadius: '50%',
                    boxShadow: 3,
                }}
            >
                {icon}
            </IconButton>
            <Typography variant="caption" sx={{ marginTop: '8px', fontWeight: 'bold' }}>
                {label}
            </Typography>
        </Box>
    );
};

export default function LandingPage(props) {
    const [isElementVisible, setIsElementVisible] = useState(false);
    const {widthBreakpoint} = useContext(GlobalContext);

    const isLargeScreen = window.outerWidth > 2100

    let height = 600
    if (window.outerWidth < widthBreakpoint) {
        height = 480
    }else if (isLargeScreen) {
        height = 850
    }

    return (
        <>
            {props.isLoginDialogOpen && <LoginDialog setIsOpen={props.setIsLoginDialogOpen} isOpen={props.isLoginDialogOpen}/> }
            <Grid container spacing={2} style={{ height: '100%' }}>
                <Grid item xs={12} md={6} style={{ height: height, paddingTop: isLargeScreen ? "100px" : "0px" }}>
                    <div style={{marginLeft: (window.outerWidth>=widthBreakpoint) ? "100px" : "0px", marginTop: isLargeScreen ? "200px" : "150px"}}>
                        <Slide direction="up" timeout={600} in={true} mountOnEnter unmountOnExit>
                            <div>
                                <Typography variant="h1" component="div" color="primary" style={{ fontWeight: 700, fontSize: (window.outerWidth >= widthBreakpoint) ? 75 : 45 }}>
                                    Let algorithms
                                </Typography>
                                <Typography variant="h1" component="div" color="primary" style={{ fontWeight: 700, fontSize: (window.outerWidth >= widthBreakpoint) ? 75 : 45 }}>
                                    do the work.
                                </Typography>
                            </div>
                        </Slide>

                        <Fade direction="up" timeout={3400} in={true}>
                            <Typography variant="h6" component="div" color="primary" style={{ marginTop: "25px", fontSize: (window.outerWidth >= widthBreakpoint) ? 28 : 20 }}>
                                Die besten Trades müssen Sie nicht selbst machen.
                            </Typography>
                        </Fade>

                        <Fade direction="up" timeout={3400} in={true}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={(e) => props.setIsLoginDialogOpen(true)}
                                style={{
                                    fontSize: '1.2rem',
                                    padding: '20px 40px',
                                    borderRadius: '8px',
                                    marginTop: "50px",
                                    fontWeight: 700
                                }}
                                endIcon={<Send/>}
                            >
                                Jetzt beginnen
                            </Button>
                        </Fade>

                    </div>
                </Grid>

                {(window.outerWidth < widthBreakpoint) &&
                    <Grid item xs={12} sx={{marginBottom: "50px", marginTop: "50px"}}>
                        <Grid container spacing={2} textAlign={"center"}>
                            <Grid item xs={4}>
                                <LargeRoundIconButton label={"News"} icon={<Newspaper onClick={(e) => props.setIsNewsDialogOpen(!props.isNewsDialogOpen)} sx={{color: "var(--blue)", fontSize: 50 }} />}/>
                            </Grid>
                            <Grid item xs={4}>
                                <LargeRoundIconButton label={"Support"} icon={<HelpCenter onClick={(e) => props.setIsSupportDialogOpen(!props.isSupportDialogOpen)} sx={{color: "var(--green)", fontSize: 50 }} />}/>
                            </Grid>
                            <Grid item xs={4}>
                                <LargeRoundIconButton label={"FAQ"} icon={<QuestionAnswer sx={{color: "var(--indigo)", fontSize: 50 }} />}/>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {(window.outerWidth >= widthBreakpoint) &&
                    <Grid item xs={12} md={6}>
                        <Slide direction="up" timeout={600} in={true} mountOnEnter unmountOnExit>
                            <div style={{marginLeft: "25px", marginTop: "100px", height: isLargeScreen ? "850px" : "600px", paddingLeft: "25px", paddingTop: isLargeScreen ? "100px" : ""}}>
                                <TradingViewWidget/>
                            </div>
                        </Slide>
                    </Grid>
                }

                {(window.outerWidth >= widthBreakpoint) ?
                    <FadeInGridElement gridElement={<InfoBox/>} setIsElementVisible={setIsElementVisible}/>
                    :
                    <InfoBox/>
                }



                <Footer isElementVisible={isElementVisible}/>
            </Grid>
        </>
    );
}
