import {
    AccountBalance,
    AccountCircle,
    AddLocationAlt,
    Email,
    Info,
    Insights,
    LocationCity,
    Phone, Signpost
} from "@mui/icons-material";
import {
    Box,
    FormControl,
    FormControlLabel, FormGroup,
    Grid,
    IconButton,
    Stack,
    Switch, Tab, Tabs,
    Tooltip,
    Typography
} from "@mui/material";
import {useContext, useState} from "react";
import {GlobalContext} from "../../GlobalContext";


function SettingsItem(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {props.icon}
                <Typography variant="body1" color="textPrimary">
                    {props.content}
                </Typography>
            </Box>
            <Tooltip title={props.info}>
                <IconButton>
                    <Info sx={{ color: 'var(--white)' }} />
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default function PersonalOptionsElement (props) {
    const [checked, setChecked] = useState({
        newsNotifications: (props.settings.data.information) ? props.settings.data.information.newsNotifications : false,
        alarmNotifications: (props.settings.data.information) ? props.settings.data.information.alarmNotifications : false
    });
    const [tabValue, setTabValue] = useState(0);

    const iconsx = { color: 'var(--white)', mr: 1, my: 0.5 };
    const switchsx = {'& .MuiSwitch-switchBase.Mui-checked': {color: 'var(--green)',}, '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {backgroundColor: 'var(--green)',}}
    const {sendRequest, updateSnackbarInfo} = useContext(GlobalContext);

    const handleChange = async (event) => {
        const name = event.target.name;
        const newValue = !checked[name]
        const res = await sendRequest("customers/settings", {[name]: newValue});
        if (res.state !== "1") {
            updateSnackbarInfo({msg: "Leider konnten die Benachrichtigungen nicht aktualisiert werden.", type: "error"});
        }else {
            setChecked({...checked, [name]: newValue});
        }
    };

    return (
        <>
            <Grid item xs={12}>
                {!props.settings.err && (
                    <div style={{ marginTop: "10px" }}>
                        <Stack spacing={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <Tabs
                                    value={tabValue}
                                    onChange={(event, newValue) => setTabValue(newValue)}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                    sx={{width: '100%', maxWidth: { xs: '100%', sm: '100%', md: '100%' }, '& .MuiTab-root': { minWidth: { xs: 'auto', sm: 120 } },}}>
                                    <Tab label="Persönlich" />
                                    <Tab label="Bankdaten" />
                                    <Tab label="Anschrift" />
                                    <Tab label="Einstellungen" />
                                </Tabs>
                            </Box>

                            {tabValue === 0 &&
                                <>
                                    <SettingsItem icon={<AccountCircle sx={iconsx} />}
                                                  content={props.settings.data.information.name}
                                                  info={"Sie können den Namen ändern, kontaktieren Sie dafür den Support."} />

                                    <SettingsItem icon={<Email sx={iconsx} />}
                                                  content={props.settings.data.email}
                                                  info={"Sie können die Email jederzeit ändern."}/>

                                    <SettingsItem icon={<Phone sx={iconsx} />}
                                                  content={`${props.settings.data.information.phone}`}
                                                  info={"Ihre Telefonnummer ist jederzeit änderbar, melden Sie sich dazu beim Support."}/>

                                </>
                            }

                            {tabValue === 1 &&
                                <>
                                    <SettingsItem icon={<AccountBalance sx={iconsx} />}
                                                  content={`${props.settings.data.payment.iban}`}
                                                  info={"Ihre Kontodaten können Sie jederzeit ändern, melden Sie sich dazu beim Support."} />

                                    <SettingsItem icon={<AccountBalance sx={iconsx} />}
                                                  content={props.settings.data.payment.bic}
                                                  info={"Ihre Kontodaten können Sie jederzeit ändern, melden Sie sich dazu beim Support."} />

                                    <SettingsItem icon={<Insights sx={iconsx} />}
                                                  content={`${props.settings.data.metaTrader.server} : ${props.settings.data.metaTrader.id}`}
                                                  info={"Gibt ihre Account ID des Brokers, sowie dessen Server Standort an. Dies könnte relevant für Ihre Steuern sein."}/>
                                </>
                            }

                            {tabValue === 2 &&
                                <>
                                    <SettingsItem icon={<Signpost sx={iconsx} />}
                                                  content={props.settings.data.information.address.street}
                                                  info={"Ihre Anschrift können Sie jederzeit ändern, melden Sie sich dazu beim Support."} />

                                    <SettingsItem icon={<LocationCity sx={iconsx} />}
                                                  content={`${props.settings.data.information.address.city} (${props.settings.data.information.address.zipcode})`}
                                                  info={"Ihre Anschrift können Sie jederzeit ändern, melden Sie sich dazu beim Support."} />
                                    {props.settings.data.information.address.addressExtra &&
                                        <SettingsItem icon={<AddLocationAlt sx={iconsx} />}
                                                      content={props.settings.data.information.address.addressExtra}
                                                      info={"Ihre Anschrift können Sie jederzeit ändern, melden Sie sich dazu beim Support."} />
                                    }
                                </>
                            }

                            {tabValue === 3 &&
                                <Box sx={{ display: 'flex', justifyContent: "center"}}>
                                    <FormControl component="fieldset" variant="standard">
                                        <FormGroup row>
                                            <FormControlLabel sx={switchsx} control={<Switch checked={checked.newsNotifications} onChange={handleChange} name="newsNotifications" />} label="News-Emails"/>
                                            <FormControlLabel sx={switchsx} style={{marginLeft: "20px"}} control={<Switch checked={checked.alarmNotifications} onChange={handleChange} name="alarmNotifications" />} label="Alarm-Emails"/>
                                        </FormGroup>
                                    </FormControl>
                                </Box>
                            }


                        </Stack>
                    </div>
                )}
            </Grid>
        </>
    );
}