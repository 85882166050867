// WRAPPER

import {Grid, IconButton, Tooltip, Typography} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import {WarningAmber} from "@mui/icons-material";
import {GlobalContext} from "../../GlobalContext";
import {useContext} from "react";

export default function InterfaceElement(props) {
    const gridItemStyle = { padding: '4px' };
    const gridContentStyle = { WebkitBackdropFilter: 'blur(2px)', width: '100%', height: '100%' };
    //const headingStyle = { textDecoration: "underline", textDecorationColor: props.color };
    const headingStyle = { };
    const skeletonStyle = { width: '100%', height: '100%', borderRadius: "12px" };

    const {widthBreakpoint} = useContext(GlobalContext)

    return (
        <Grid item xs={props.xs} md={props.md} className="grid-elements" style={gridItemStyle}>
            {!props.data.isLoaded ?
                <Skeleton variant="rectangular" style={skeletonStyle}/>
                :
                <div className="grid-content" style={gridContentStyle}>
                    <Grid container>
                        <Grid xs={12} item>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Typography component="h4" style={headingStyle}>
                                    {props.title}
                                </Typography>
                                {props.data.err &&
                                    <Tooltip title={props.data.err}>
                                        <IconButton>
                                            <WarningAmber sx={{color: "var(--red)", marginLeft: "8px", marginTop: "14px"}}/>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>
                        </Grid>

                        {!props.data.err &&
                            <div style={{marginLeft: "25px", width: (window.outerWidth>=widthBreakpoint) ? "95%" : "90%"}}>
                                {props.children}
                            </div>
                        }

                    </Grid>

                </div>
            }
        </Grid>
    );
}