import React, { useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function FadeInGridElement({ gridElement, setIsElementVisible }) {
    const controls = useAnimation();
    const containerRef = useRef(null);
    //const [isVisible, setIsVisible] = useState(false);

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    //setIsVisible(true);
                    controls.start({
                        opacity: 1,
                        transition: { duration: 1 }
                    });
                } else {
                    //setIsVisible(false);
                    controls.start({
                        opacity: 0,
                        transition: { duration: 1 }
                    });
                }
                setIsElementVisible(entry.isIntersecting);
            },
            { threshold: 0.25 }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [controls, setIsElementVisible]);

    return (
        <Grid
            item
            xs={12}
            md={12}
            style={{
                marginTop: isLargeScreen ? "200px" : "100px"
            }}
        >
            <motion.div
                ref={containerRef}
                initial={{ opacity: 0 }}
                animate={controls}
                style={{
                    height: isLargeScreen ? "900px" : "700px"
                }}
            >
                {gridElement}
            </motion.div>
        </Grid>
    );
}
