import Plot from 'react-plotly.js';

const InterfacePlotlyChart = (props) => {

    const isLargeSize = window.outerWidth>2000;
    let width = isLargeSize ? 760 : 560;
    if (props.width) {
        width = props.width + width
    }
    const layout = {
        plot_bgcolor: 'black',
        paper_bgcolor: 'black',
        xaxis: {
            color: 'white',
            automargin: true,
            type: 'date'
        },
        yaxis: {
            color: 'white',
            automargin: true,
        },
        margin: {
            l: 20,
            r: 20,
            b: 20,
            t: 20,
            pad: 0
        },
        showlegend: false,
        autosize: true,
    };


    return (
        <>
            {(props.data && props.data.length >= 1) &&
                <Plot
                    data={props.data}
                    layout={props.layout ? props.layout : layout}
                    style={{ width: `100%`, height: '230px' }}
                    config={{ displayModeBar: false, scrollZoom: true }}
                />
            }
        </>
    );
};

export default InterfacePlotlyChart;