import {Box, Grid, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../GlobalContext";

export default function Footer(props) {
    const {widthBreakpoint} = useContext(GlobalContext);

    useEffect(() => {
    }, [props.isElementVisible]);

    return(
        <Grid item xs={12}>
            <Box
                sx={{
                    position: ((window.outerWidth >= widthBreakpoint) && props.isElementVisible) ? 'static' : 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    p: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    zIndex: 1000
                }}
            >
                <Grid container justifyContent={{ xs: 'center', md: 'left' }} alignItems="center">
                    <Grid item xs={6} sm={4} md={1}>
                        <Typography align="center" sx={{ fontWeight: 700, fontSize: { xs: "12px", md: "14px" } }}>Impressum</Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} md={1}>
                        <Typography align="center" sx={{ fontWeight: 700, fontSize: { xs: "12px", md: "14px" } }}>Datenschutz</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )

}