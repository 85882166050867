import {
    Box,
    Button, CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, IconButton, InputAdornment, TextField,
    Typography
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import DOMPurify from 'dompurify';
import {GlobalContext} from "../../GlobalContext";
import {Email, LockOpen, Password} from "@mui/icons-material";
import {validateInput} from "../utils/validator";

const sxInputFields = {
    '& .MuiInputLabel-root': { color: 'var(--white)' },
    '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'var(--white)' },
        '&:hover fieldset': { borderColor: 'var(--white)' },
        '&.Mui-focused fieldset': { borderColor: 'var(--blue)' },
    },
    '& .MuiInputBase-input': { color: 'var(--white)' }
}

export default function LoginDialog(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [info, setInfo] = useState({msg: "Die Sitzungen sind 30 Minuten lang gültig.", type: 0});
    const [isLoading, setIsLoading] = useState(false);
    const [alreadyLoggedIn, setIsAlreadyLoggedIn] = useState(null);

    const { login, sendRequest, updateSnackbar } = useContext(GlobalContext);

    const changeValue = (e, type) => {
        const value = DOMPurify.sanitize(e.target.value);
        if (type === "email" && value.length < 64 ) {
            setEmail(value);
            return;
        }
        if (type === "password" && value.length <= 128 ) {
            setPassword(value);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) return;

        try {
            setIsLoading(true);
            const isOk = await login(email, password);
            setIsLoading(false);
            if (!isOk) {
                setInfo({msg: "Die Anmeldedaten konnten nicht gefunden werden.", type: 1});
                setTimeout(() => setInfo({msg: "", type: 0}), 1000*5);
                return;
            }
            props.setIsOpen(false);
        } catch (error) {
            setInfo({msg: "Hopla, da ist was schief gelaufen!", type: 1});
            setTimeout(() => setInfo({msg: "", type: 0}), 1000*5);
        }
    };


    const checkIfAlreadyLoggedIn = async () => {
        try {
            const res = await sendRequest("auth/verify", {});
            if (res.state==="1") {
                const isOk = await login(res, null);
                if (isOk){
                    setIsAlreadyLoggedIn(true);
                    return;
                }
            }
            setIsAlreadyLoggedIn(false);
        }catch (e) {
            updateSnackbar({msg: "Ihre Sitzung konnte nicht geprüft werden. Sind Cookies für diese Website erlaubt?", type: "error"});
            setIsAlreadyLoggedIn(false);
        }
    }

    useEffect(() => {
        checkIfAlreadyLoggedIn()
    }, []);

    return (
        <>
            <Dialog
                open={props.isOpen}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        if ((validateInput(email, "email") && validateInput(password, "password"))) {
                            handleSubmit(e)
                        }
                    }
                }}
                onClose={(e) => {e.preventDefault();props.setIsOpen(false);}}
                PaperProps={{sx: {backgroundColor: 'var(--black)', borderRadius: '12px'}}}
            >
                <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ textDecoration: "underline var(--blue)", fontSize: 26 }}>Anmelden</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                        {(alreadyLoggedIn===null) && <CircularProgress />}
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => changeValue(e, "email")}
                            sx={sxInputFields}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end"> <Email/> </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => changeValue(e, "password")}
                            sx={sxInputFields}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end"> <Password/> </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <div style={{marginTop: "2%"}}>
                            <Typography variant={"subtitle2"} sx={{color: info.type===1 ? "var(--orange)" : "var(--white)"}}>{info.msg}</Typography>
                        </div>
                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button variant={"outlined"} onClick={(e) => {e.preventDefault();props.setIsOpen(false);}}>Schließen</Button>
                    {(validateInput(email, "email") && validateInput(password, "password")) &&
                        <Button endIcon={<LockOpen/>} loading={isLoading} variant={"outlined"} onClick={handleSubmit}>Login</Button>
                    }
                </DialogActions>

            </Dialog>
        </>
    );
}
