import {Box, Breadcrumbs, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {Euro, Info, SwapVerticalCircle, TrendingDown, TrendingFlat, TrendingUp} from "@mui/icons-material";
import {GlobalContext} from "../../GlobalContext";


export default function QuickStatsElement(props) {
    const [isTimeChangePossible, setIsTimeChangePossible] = useState(props.balance.data.length > 2);
    const [isMonthlySelect, setIsMonthlySelect] = useState(true)
    const [percentageDiff, setPercentageDiff] = useState(1);
    const [prognosis, setPrognosis] = useState({ balance: 1, percentage: 1 });
    const [euroDiff, setEuroDiff] = useState(1);

    const {updateSnackbarInfo, widthBreakpoint} = useContext(GlobalContext);

    const onClickBreadcrumb = (e) => {
        if (!isTimeChangePossible) {
            e.preventDefault();
            updateSnackbarInfo({msg: "Die Jahresansicht ist erst verfügbar, wenn dein Account älter als einen Monat ist.", type: "warning"});
            setIsMonthlySelect(true);
            return;
        }
        setIsMonthlySelect(!isMonthlySelect);
    }

    const calcPercentageDiff = (oldest, newest) => {
        if (oldest === 0) return 0;
        return ((newest - oldest) / oldest) * 100;
    }

    function calcPrognosis(firstEntry, lastEntry) {
        const balanceDifference = lastEntry.balance - firstEntry.balance;
        const timeDifference = (new Date(lastEntry.time) - new Date(firstEntry.time)) / (1000 * 60 * 60 * 24);

        let projectedBalance;
        if (isMonthlySelect) {
            const daysInMonth = 30.44;
            projectedBalance = firstEntry.balance + (balanceDifference / timeDifference) * daysInMonth;
        } else {
            const daysInYear = 365.25;
            projectedBalance = firstEntry.balance + (balanceDifference / timeDifference) * daysInYear;
        }

        const percentageDifference = ((projectedBalance - firstEntry.balance) / firstEntry.balance) * 100;

        return {balance: projectedBalance.toFixed(2), percentage: Math.round(percentageDifference)};
    }

    const init = () => {
        let lastIndex = props.balance.data.length === 3 ? 2 : 1;
        if (isMonthlySelect) lastIndex = 1;

        let oldestValue = props.balance.data[lastIndex]?.balance || 0;
        let newestValue = props.balance.data[0]?.balance || 0;

        const percentageDiff = calcPercentageDiff(oldestValue, newestValue);
        const euroDiff = newestValue - oldestValue;

        if (isMonthlySelect && (oldestValue === newestValue)) {
            setIsMonthlySelect(false);
            setEuroDiff(0);
            setPercentageDiff(0);
            setPrognosis({
                balance: newestValue.toFixed(2),
                percentage: 0,
            });
        } else {
            const prognosis = calcPrognosis(props.balance.data[lastIndex], props.balance.data[0]);
            setEuroDiff(euroDiff.toFixed(2));
            setPercentageDiff(percentageDiff.toFixed(2));
            setPrognosis(prognosis);
        }
    }


    useEffect(() => init(), [props.balance, isMonthlySelect]);

    return (
        <Grid item xs={12}>
            {!props.balance.err && (
                <div style={{ marginTop: "10px" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="subtitle1">Statistik ab: </Typography>
                        <Typography variant="subtitle1">{new Date(props.balance.data[props.balance.data.length - 1].time).toLocaleDateString()}</Typography>
                    </Box>
                    <hr style={{color: "var(--white)"}}/>


                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>

                            {(percentageDiff === 0) &&
                                <>
                                    <TrendingFlat sx={{ color: 'var(--white)', mr: 1, my: 0.5 }} />
                                    <Typography variant={(window.outerWidth>=widthBreakpoint) ? "h6" : "subtitle1"} color="textPrimary">Aktuell gibt es keine Veränderung</Typography>
                                </>
                            }

                            {(percentageDiff > 0) &&
                                <>
                                    <TrendingUp sx={{ color: 'var(--green)', mr: 1, my: 0.5 }} />
                                    <Typography variant={(window.outerWidth>=widthBreakpoint) ? "h6" : "subtitle1"} color="textPrimary">Prozentualer Gewinn: {percentageDiff}%</Typography>
                                </>
                            }

                            {(percentageDiff < 0) &&
                                <>
                                    <TrendingDown sx={{ color: 'var(--red)', mr: 1, my: 0.5 }} />
                                    <Typography variant={(window.outerWidth>=widthBreakpoint) ? "h6" : "subtitle1"} color="textPrimary">Prozentualer Verlust: {percentageDiff}%</Typography>
                                </>
                            }

                        </Box>
                        <Tooltip title={"Die prozentuale Differenz wird gerundet angegeben."}>
                            <IconButton><Info sx={{ color: 'var(--white)' }} /></IconButton>
                        </Tooltip>
                    </Box>


                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>

                            {(percentageDiff === 0) &&
                                <>
                                    <Euro sx={{ color: 'var(--white)', mr: 1, my: 0.5 }} />
                                    <Typography variant={(window.outerWidth>=widthBreakpoint) ? "h6" : "subtitle1"} color="textPrimary">Aktuell gibt es keine Veränderung</Typography>
                                </>
                            }

                            {(percentageDiff > 0) &&
                                <>
                                    <Euro sx={{ color: 'var(--green)', mr: 1, my: 0.5 }} />
                                    <Typography variant={(window.outerWidth>=widthBreakpoint) ? "h6" : "subtitle1"} color="textPrimary">Gewinn: {euroDiff}€</Typography>
                                </>
                            }

                            {(percentageDiff < 0) &&
                                <>
                                    <Euro sx={{ color: 'var(--red)', mr: 1, my: 0.5 }} />
                                    <Typography variant={(window.outerWidth>=widthBreakpoint) ? "h6" : "subtitle1"} color="textPrimary">Verlust: {euroDiff}€</Typography>
                                </>
                            }

                        </Box>
                        <Tooltip title={"Der aktuelle Verlust wird gerundet angegeben."}>
                            <IconButton><Info sx={{ color: 'var(--white)' }} /></IconButton>
                        </Tooltip>
                    </Box>


                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>

                            { (prognosis.percentage === 0) &&
                                <>
                                    <SwapVerticalCircle sx={{ color: 'var(--white)', mr: 1, my: 0.5 }} />
                                    <Typography variant={(window.outerWidth>=widthBreakpoint) ? "h6" : "subtitle1"} color="textPrimary">Prognose: Aktuell noch nicht verfügbar</Typography>
                                </>
                            }

                            { (prognosis.percentage > 0) &&
                                <>
                                    <SwapVerticalCircle sx={{ color: 'var(--green)', mr: 1, my: 0.5 }} />
                                    <Typography variant={(window.outerWidth>=widthBreakpoint) ? "h6" : "subtitle1"} color="textPrimary">Prognose: {prognosis.balance} Euro (<span style={{textDecoration: "1px var(--green) underline"}}>{prognosis.percentage}%</span>)</Typography>                                </>
                            }

                            { (prognosis.percentage < 0) &&
                                <>
                                    <SwapVerticalCircle sx={{ color: 'var(--red)', mr: 1, my: 0.5 }} />
                                    <Typography variant={(window.outerWidth>=widthBreakpoint) ? "h6" : "subtitle1"} color="textPrimary">Prognose: {prognosis.balance} Euro ({prognosis.percentage}%)</Typography>
                                </>
                            }

                        </Box>
                        <Tooltip title={"Die Prognose ist rein statistisch und basiert auf dem bisherigen Gewinn/Verlust des Monats!"}>
                            <IconButton><Info sx={{ color: 'var(--white)' }} /></IconButton>
                        </Tooltip>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: "center", marginTop: "15px"}}>
                        <Breadcrumbs aria-label="breadcrumb" onClick={onClickBreadcrumb}>
                            <Typography color="primary" sx={{cursor: "pointer", fontWeight: (isMonthlySelect) ? 700 : 400}}>Monat</Typography>
                            <Typography color="primary" sx={{cursor: "pointer", fontWeight: (isMonthlySelect) ? 400 : 700}}>Jahr</Typography>
                        </Breadcrumbs>
                    </Box>


                </div>
            )}
        </Grid>
    );
}