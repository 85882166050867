import {
    Autocomplete,
    Button,
    Grid,
    Paper,
    Slider,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import DOMPurify from "dompurify";
import {Repeat, SettingsBackupRestore} from "@mui/icons-material";
import {GlobalContext} from "../../../GlobalContext";
import {styled} from "@mui/styles";
import OverviewCustomerDialog from "../../menus/OverviewCustomerDialog";

const sxInputFields = {
    '& .MuiInputLabel-root': { color: 'var(--white)' },
    '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'var(--white)' },
        '&:hover fieldset': { borderColor: 'var(--white)' },
        '&.Mui-focused fieldset': { borderColor: 'var(--blue)' },
    },
    '& .MuiInputBase-input': { color: 'var(--white)' }
};

const sxInputFieldsChanged = {
    '& .MuiInputLabel-root': { color: 'var(--green)' },
    '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'var(--green)' },
        '&:hover fieldset': { borderColor: 'var(--green)' },
        '&.Mui-focused fieldset': { borderColor: 'var(--green)' },
    },
    '& .MuiInputBase-input': { color: 'var(--white)' }
};


const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: 'var(--gray-scale-8)',
    color: 'var(--white)',
    borderRadius: 4,
    boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
}));

export default function ModifyCustomerElement(props) {
    const [inputs, setInputs] = useState({
        name: "",
        phonenumber: "",
        email: "",
        street: "",
        city: "",
        zipcode: "",
        addressExtra: "",
        iban: "",
        bic: "",
        percentageWin: 1
    });
    const [useSelectedCustomer, setSelectedCustomer] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(null);
    const [isOverviewOpen, setIsOverviewOpen] = useState(false);

    const {updateSnackbarInfo, sendRequest} = useContext(GlobalContext);

    const init = () => {
        const newOptions = props.customers.data.map((item) => `${item.email} (${item.information.name})`);
        setOptions(newOptions);
    };

    useEffect(() => {
        init();
    }, [props.customers]);

    const onChange = (e, type) => {
        let content = DOMPurify.sanitize(e.target.value);
        setInputs(prev => ({...prev, [type]: content}));
    }

    const onSliderChange = (event, newValue) => {
        setInputs(prev => ({...prev, percentageWin: newValue}));
    };

    const onClick = async (e) => {
        const cinputs = {...inputs};
        let res = null;
        if (cinputs.addressExtra && cinputs.addressExtra.length <= 1) {
            delete cinputs.addressExtra;
            res = await sendRequest("admin/modifyc", cinputs);
        }else {
            res = await sendRequest("admin/modifyc", cinputs);
        }

        if (res.state === "-115") {
            updateSnackbarInfo({ msg: "Dazu hast du keine Berechtigung!", type: "warning" });
            return;
        }

        if (res.state === "-116") {
            updateSnackbarInfo({ msg: "Der Nutzer wurde nicht gefunden oder ist Owner.", type: "error" });
            return;
        }

        if (res.state === "-118") {
            updateSnackbarInfo({ msg: "Ein unerwarteter interner Fehler ist aufgetreten!", type: "error" });
            return;
        }

        if (res.state === "1") {
            setInputs({
                name: "",
                phonenumber: "",
                email: "",
                street: "",
                city: "",
                zipcode: "",
                addressExtra: "",
                iban: "",
                bic: "",
                percentageWin: 1
            });
            setSelectedCustomer(null);
            setValue(null);
            setTabValue(0);
            updateSnackbarInfo({ msg: "Die Änderungen wurden erfolgreich gespeichert.", type: "success" });
            props.updateAdminData();
        }else {
            updateSnackbarInfo({ msg: "Ein unerwarteter Fehler ist aufgetreten.", type: "error" });
        }

    }

    return (
        <>
            {isOverviewOpen && <OverviewCustomerDialog isOpen={isOverviewOpen} setIsOpen={setIsOverviewOpen} selectedCustomer={useSelectedCustomer}/>}
            <Grid item xs={12}>
                <div style={{width: "100%", height: "100%"}}>
                    <hr style={{color: "var(--white)"}}/>
                    <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)} variant="scrollable" scrollButtons>
                        <Tab label="Auswahl" />
                        <Tab label="Persönlich" disabled={!value}/>
                        <Tab label="Adresse" disabled={!value}/>
                        <Tab label="System" disabled={!value}/>
                        <Tab label="Abschluss" disabled={!value}/>
                    </Tabs>
                    <Grid container spacing={2}>
                        <Grid item xs={12}></Grid>

                        {tabValue === 0 && (
                            <>
                                <Grid item xs={12}>
                                    <div style={{marginTop: "25px"}}>
                                        <Autocomplete
                                            value={value}
                                            onChange={(event, newValue) => {
                                                setValue(newValue);
                                                if (newValue) {
                                                    const foundCustomer = props.customers.data.find((item) => item.email === newValue.split(" ")[0]);
                                                    if (foundCustomer) {
                                                        setSelectedCustomer(foundCustomer);
                                                        setInputs({
                                                            name: foundCustomer?.information?.name || "",
                                                            phonenumber: foundCustomer?.information?.phone || "",
                                                            email: foundCustomer?.email || "",
                                                            street: foundCustomer?.information?.address?.street || "",
                                                            city: foundCustomer?.information?.address?.city || "",
                                                            zipcode: foundCustomer?.information?.address?.zipcode || "",
                                                            addressExtra: foundCustomer?.information?.address?.addressExtra || "",
                                                            percentageWin: foundCustomer?.percentageWin
                                                        });
                                                    }
                                                }else {
                                                    setInputs({});
                                                    setSelectedCustomer(null);
                                                }
                                            }}
                                            options={options}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextField{...params} label="Wähle einen Nutzer" variant="outlined" sx={!!value ? sxInputFieldsChanged : sxInputFields}/>
                                            )}
                                            PaperComponent={(props) => <StyledPaper {...props} />}
                                            ListboxProps={{
                                                sx: {
                                                    '& .MuiAutocomplete-option': {
                                                        color: 'var(--dropdown-text)',
                                                        '&[data-focus="true"]': {
                                                            backgroundColor: 'var(--dropdown-hover-background)',
                                                            color: 'var(--dropdown-hover-text)',
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </div>

                                    {useSelectedCustomer !== null &&
                                        <div style={{marginTop: "15px"}}>
                                            <Button variant={"outlined"} color={"primary"} onClick={(e) => setIsOverviewOpen(true)}>Übersicht</Button>
                                        </div>
                                    }

                                </Grid>
                            </>
                        )}

                        {tabValue === 1 && (
                            <>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        label="Name"
                                        type="text"
                                        placeholder="Neuer Name"
                                        onChange={(e) => onChange(e, "name")}
                                        value={inputs.name || ""}
                                        sx={(inputs.name === useSelectedCustomer.information.name) ? sxInputFields : sxInputFieldsChanged}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        sx={(inputs.phonenumber === useSelectedCustomer.information.phone) ? sxInputFields : sxInputFieldsChanged}
                                        required
                                        type="text"
                                        label="Telefonnummer"
                                        placeholder={"+490000000000"}
                                        onChange={(e) => onChange(e, "phonenumber")}
                                        value={inputs.phonenumber || ""}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        sx={(inputs.email === useSelectedCustomer.email) ? sxInputFields : sxInputFieldsChanged}
                                        value={inputs.email || ""}
                                        placeholder={"test@plutos.bot"}
                                        onChange={(e) => onChange(e, "email")}
                                        label="Email"
                                        type="email"
                                    />
                                </Grid>
                            </>
                        )}

                        {tabValue === 2 && (
                            <>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        sx={(inputs.street === useSelectedCustomer.information.address.street) ? sxInputFields : sxInputFieldsChanged}
                                        value={inputs.street || ""}
                                        placeholder={"Landstraße 1."}
                                        onChange={(e) => onChange(e, "street")}
                                        label="Straße und Hausnummer"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        sx={(inputs.city === useSelectedCustomer.information.address.city) ? sxInputFields : sxInputFieldsChanged}
                                        value={inputs.city || ""}
                                        placeholder={"Dresden"}
                                        onChange={(e) => onChange(e, "city")}
                                        label="Stadt"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        sx={(inputs.zipcode === useSelectedCustomer.information.address.zipcode) ? sxInputFields : sxInputFieldsChanged}
                                        value={inputs.zipcode || ""}
                                        placeholder={"01123"}
                                        onChange={(e) => onChange(e, "zipcode")}
                                        label="Postleitzahl"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        sx={ (useSelectedCustomer.information.address.addressExtra) && (inputs.addressExtra === useSelectedCustomer.information.address.addressExtra) ? sxInputFields : sxInputFieldsChanged}
                                        value={inputs.addressExtra || ""}
                                        placeholder={"Wohnung 00"}
                                        onChange={(e) => onChange(e, "addressExtra")}
                                        label="Adresszusatz"
                                        type="text"
                                    />
                                </Grid>
                            </>
                        )}

                        {tabValue === 3 && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant={"subtitle1"}>Prozentuale Kommission pro Gewinn (%)</Typography>
                                    <Slider
                                        value={inputs.percentageWin}
                                        color={ (inputs.percentageWin !== useSelectedCustomer.percentageWin) ? "success" : "primary"}
                                        getAriaValueText={(value) => `${value}%`}
                                        valueLabelDisplay="auto"
                                        onChange={onSliderChange}
                                        step={0.5}
                                        marks
                                        min={1}
                                        max={10}
                                    />
                                </Grid>
                            </>
                        )}

                        {tabValue === 4 && (
                            <>
                                <Grid item xs={6}>
                                    <Button endIcon={<Repeat/>} onClick={() => {setInputs({}); setTabValue(0); setValue(null); setSelectedCustomer(null)}}
                                            variant={"contained"}
                                            color={"warning"}
                                            fullWidth>
                                        Neustarten
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button endIcon={<SettingsBackupRestore/>} onClick={onClick} variant={"contained"} color={"success"} fullWidth>Nutzer updaten</Button>
                                </Grid>
                            </>
                        )}

                    </Grid>
                </div>
            </Grid>
        </>
    );
}
