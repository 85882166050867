import { Grid } from "@mui/material";
import InterfacePlotlyChart from "../libs/InterfacePlotlyChart";
import { useEffect, useState } from "react";

export default function HistoryElement(props) {
    const [useData, setData] = useState(null);

    const getFirstAvailableDayOfWeek = (history, weekEndDate) => {
        const daysOfWeek = [1, 2, 3, 4, 5]; // Montag bis Freitag
        for (let i = daysOfWeek.length - 1; i >= 0; i--) {
            const foundDay = history.find(item => {
                const date = new Date(item.date);
                return date.getDay() === daysOfWeek[i] && date < new Date(weekEndDate);
            });
            if (foundDay) {
                return foundDay; // Gib das gesamte Objekt zurück
            }
        }
        return null;
    };

    const init = () => {
        if (useData === null && props.history.data.length > 0) {
            const lineData = {
                x: [],
                y: [],
                type: 'scatter',
                mode: 'lines',
                line: {
                    color: props.history.data[0].averageEquity < props.history.data[props.history.data.length - 1].averageEquity ? '#06d6a0' : '#c1121f'
                },
                text: [],
                hoverinfo: 'text'
            };

            const markerData = {
                x: [],
                y: [],
                type: 'scatter',
                mode: 'markers',
                marker: {
                    color: [],
                    symbol: [],
                    size: 15
                },
                text: [],
                hoverinfo: 'text'
            };

            let mondayValue = null;

            for (const item of props.history.data) {
                const date = new Date(item.date);
                const day = date.getDay(); // Sonntag = 0, Montag = 1, Freitag = 5

                // Daten für die Linie hinzufügen
                lineData.x.push(item.date);
                lineData.y.push(item.averageEquity);
                lineData.text.push(`${item.averageEquity.toFixed(2)}€`);

                // Prüfe, ob es einen Montag gibt, oder den ersten Tag der Woche nehmen
                if (day === 1) {
                    mondayValue = { date: item.date, averageEquity: item.averageEquity }; // Speichere das gesamte Objekt
                }

                // Wenn es Freitag ist und wir haben keinen Montag, dann nimm den ersten Tag der Woche
                if (day === 5) {
                    if (mondayValue === null) {
                        mondayValue = getFirstAvailableDayOfWeek(props.history.data, item.date); // Hol das gesamte Objekt
                    }

                    if (mondayValue !== null) {
                        const difference = item.averageEquity - mondayValue.averageEquity;
                        markerData.x.push(item.date);
                        markerData.y.push(item.averageEquity);

                        if (difference > 0) {
                            markerData.marker.color.push('#60e550');
                            markerData.marker.symbol.push('triangle-up');
                        } else if (difference < 0) {
                            markerData.marker.color.push('#ff5500');
                            markerData.marker.symbol.push('triangle-down');
                        } else {
                            markerData.marker.color.push('#000000');
                            markerData.marker.symbol.push('circle');
                        }
                        markerData.text.push(`${item.averageEquity.toFixed(2)}€ <br/> Im Vergleich zum ${mondayValue.date}`);
                    }
                }
            }

            // Setze beide Datenreihen (Linie und Marker)
            setData([lineData, markerData]);
        }
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <Grid item xs={12}>
            <div style={{ width: "100%", height: "100%" }}>
                <InterfacePlotlyChart
                    data={useData}
                    width={650}
                    layout={
                        useData === null
                            ? null
                            : {
                                plot_bgcolor: "black",
                                paper_bgcolor: "black",
                                xaxis: {
                                    color: "white",
                                    automargin: true,
                                    type: "date",
                                    range: [
                                        useData[0].x[0],
                                        useData[0].x[useData[0].x.length - 1]
                                    ],
                                    fixedrange: true,
                                    rangeslider: {
                                        visible: true,
                                        range: [
                                            useData[0].x[0],
                                            useData[0].x[useData[0].x.length - 1]
                                        ]
                                    }
                                },
                                yaxis: {
                                    color: "white",
                                    automargin: true,
                                    fixedrange: false
                                },
                                margin: {
                                    l: 20,
                                    r: 20,
                                    b: 20,
                                    t: 20,
                                    pad: 0
                                },
                                showlegend: false,
                                autosize: true,
                                dragmode: "zoom"
                            }
                    }
                />
            </div>
        </Grid>
    );
}
