
export function sendBanner() {
    setTimeout(() => {

        console.log('%c Guck Guck!', 'color: cyan; font-weight: bold; font-size: 28px');


        console.log('%cDu kennst dich gut mit Computern aus und weißt, wie der Hase läuft? \n\
Dann melde dich gerne bei uns! \nAnsonsten freuen wir uns auch sehr über gefundene Bugs oder Sicherheitslücken.\n\n\
Verwende in deinem UserAgent einfach folgende Kennung: %cTT-31337%c\n\nBeispiel:\n%cMozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/85.0.4183.121 Safari/537.36 (TT-31337)%c',
            'color: white; font-size: 14px;',
            'color: red; font-weight: bold;',
            'color: white;',
            'color: green; font-weight: bold;',
            'color: white;');

    }, 5000)

}