import {Box, Grid, TextField, Typography} from "@mui/material";
import video from './../../assets/presentation.mp4';
import {useContext, useEffect, useRef} from "react";
import {GlobalContext} from "../../GlobalContext";

export default function InfoBox(props) {
    const videoRef = useRef(null);
    const {widthBreakpoint} = useContext(GlobalContext);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.playbackRate = 0.75;
            videoElement.play().catch(error => {
                console.log('Autoplay verhindert:', error);
            });
        }
    }, []);

    return (
        <Box sx={{
            height: {xs: "auto", md: "700px"},
            borderRadius: "24px",
            backdropFilter: 'blur(2px)',
            WebkitBackdropFilter: 'blur(2px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: "16px"
        }}>
            <Grid container spacing={2} sx={{ height: "100%" }}>
                <Grid item xs={12} style={{marginBottom: "50px"}}>
                    <Typography
                        variant="h4"
                        color="primary"
                        sx={{
                            fontWeight: 700,
                            textAlign: "center",
                            fontSize: { xs: "32px", md: "52px" },
                            textDecoration: "3px underline var(--white)"
                        }}>
                        Der Autopilot für Ihr Portfolio!
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                    {(window.outerWidth >= widthBreakpoint) ?
                        <div>
                            <Typography variant={"h6"} sx={{fontSize: {xs: "18px", md: "20px"}, fontWeight: 700}}>
                                Willkommen bei Trabo-Trading – Ihrem Partner für automatisierte Portfolioverwaltung.
                            </Typography>
                            <Typography variant={"subtitle1"} sx={{fontSize: {xs: "16px", md: "20px"}}}>
                                In einer sich ständig verändernden Finanzwelt, in der präzise und datenbasierte
                                Entscheidungen unerlässlich sind, haben wir eine Lösung entwickelt, die Ihnen den Stress
                                der täglichen Handelsentscheidungen abnimmt: unseren leistungsstarken Trading-Bot.
                                Dieser wurde konzipiert, um Ihre Investments effizient zu verwalten und auf Grundlage
                                einer klar definierten Strategie in ausgewählten Märkten zu handeln.
                                Dabei setzt er konsequent auf Datenanalyse und algorithmische Logik – frei von den
                                emotionalen Einflüssen, die häufig zu suboptimalen Entscheidungen führen.
                            </Typography>

                            <Typography variant={"h6"}
                                        sx={{fontSize: {xs: "18px", md: "20px"}, fontWeight: 700, marginTop: "20px"}}>
                                Warum Trabo-Trading?
                            </Typography>
                            <Typography variant={"subtitle1"} sx={{fontSize: {xs: "18px", md: "20px"}}}>
                                Als Startup aus Sachsen bieten wir moderne Technologie zur Vermögensbildung, ohne leere Versprechen zu geben. Unser Bot handelt präzise durch fortschrittliche Datenanalyse und das in Echtzeit.
                            </Typography>

                            <Typography variant={"subtitle1"} sx={{fontSize: {xs: "18px", md: "20px"}, marginTop: "20px"}}>
                                Ehrlichkeit und Transparenz stehen bei uns an erster Stelle. Daher ist es wichtig zu betonen, dass beim Trading natürlich stets Risiken bestehen und Verluste unvermeidlich sein können. Unser Trading-Bot ist darauf ausgerichtet, Ihre Erfolgschancen bestmöglich zu optimieren. Was wir Ihnen bieten, ist eine fundierte, datenbasierte Strategie und eine Partnerschaft, die auf Vertrauen und Fairness aufgebaut ist.
                            </Typography>
                        </div>
                        :
                        <div style={{marginBottom: "25px"}}>
                            <Typography variant={"h6"} sx={{fontSize: {xs: "18px", md: "20px"}, fontWeight: 700}}>
                                Willkommen bei Trabo-Trading – Ihrem Partner für automatisierte Portfolioverwaltung.
                            </Typography>
                            <Typography variant={"subtitle1"} sx={{fontSize: {xs: "16px", md: "20px"}}}>
                                Unser Trading-Bot übernimmt den Stress der täglichen Handelsentscheidungen und verwaltet
                                Ihre Investments effizient – basierend auf klar definierten Strategien und Datenanalyse.
                            </Typography>

                            <Typography variant={"h6"}
                                        sx={{fontSize: {xs: "18px", md: "20px"}, fontWeight: 700, marginTop: "20px"}}>
                                Warum Trabo-Trading?
                            </Typography>
                            <Typography variant={"subtitle1"} sx={{fontSize: {xs: "16px", md: "18px"}}}>
                                Wir sind ein innovatives Startup aus Sachsen, das Ihnen mit modernster Technologie beim
                                langfristigen Vermögensaufbau hilft – ohne unrealistische Versprechungen.
                            </Typography>

                            <Typography variant={"subtitle1"}
                                        sx={{fontSize: {xs: "16px", md: "18px"}, marginTop: "10px"}}>
                                Ehrlichkeit und Transparenz sind uns wichtig. Trading bringt Risiken mit sich, und unser
                                Bot maximiert Ihre Erfolgschancen, ohne Gewinne zu garantieren.
                            </Typography>
                        </div>

                    }

                </Grid>


                {(window.outerWidth >= widthBreakpoint) &&
                    <Grid item xs={12} md={6}
                          sx={{display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center"}}>
                        <div>
                            <div style={{height: "100%"}}>
                                <video ref={videoRef}
                                       width="80%"
                                       height="auto"
                                       controls={false}
                                       autoPlay={true}
                                       muted={true}
                                       loop
                                       style={{boxShadow: "0 4px 6px rgba(255, 255, 255, 0.2)"}}>
                                    <source src={video} type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    </Grid>
                }

            </Grid>
        </Box>
    )

}