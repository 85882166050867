import {
    Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Grid,
    IconButton, Pagination,
    Typography
} from "@mui/material";
import {Close, ExpandMore, Fullscreen, FullscreenExit} from "@mui/icons-material";
import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../GlobalContext";


export default function NewsDialog(props) {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [selectedPage, setSelectedPage] = useState(1);
    const [news, setNews] = useState(null);

    const [isLoaded, setIsLoaded] = useState(false);

    const {updateSnackbarInfo} = useContext(GlobalContext);

    useEffect(() => {
        fetch('/news.json')
            .then(async (res) =>  await res.json())
            .then((json) => {
                const sortedArray = json.sort((a, b) => {
                    return new Date(b.datetime) - new Date(a.datetime);
                });


                if (Array.isArray(json)) {
                    const newsObject = [];
                    let currentPage = 1;
                    let counterElementsOnPage = 1;

                    for (const element of sortedArray) {
                        if (element.color === undefined || element.fullText  === undefined || element.images === undefined
                            || element.icon  === undefined || element.text  === undefined || element.title  === undefined || element.type  === undefined) {
                            continue;
                        }
                        if (!newsObject[currentPage]) newsObject[currentPage] = [];
                        newsObject[currentPage].push(element);
                        if (counterElementsOnPage === 5) {
                            counterElementsOnPage = 0;
                            currentPage++;
                        }
                        counterElementsOnPage++;
                    }

                    setIsLoaded(true);
                    setNews(newsObject);
                }else {
                    updateSnackbarInfo({msg: "Ein unerwarteter Fehler ist beim darstellen der News ist aufgetreten. Entschuldige die Unannehmlichkeiten!", type: "warning"});
                    props.setIsOpen(false);
                }
            })
            .catch(err => {
                console.error(err);
                updateSnackbarInfo({msg: "Hoppla, leider ist beim Laden der Neuigkeiten ein Fehler aufgetreten! ", type: "error"});
                props.setIsOpen(false);
            });

    }, []);

    const handlePageChange = (event, value) => {
        setSelectedPage(value);
    };

    const months = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
    const convertDatetime = (dt) => {
        const datetime = new Date(dt);
        const month = months[datetime.getMonth()];
        return `${datetime.getDate()}. ${month}`
    }

    return(
        <>
            <Dialog
                open={props.isOpen}
                onClose={(e) => {e.preventDefault();props.setIsOpen(false);}}
                fullScreen={isFullscreen}
                sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}>

                <DialogTitle id="alert-dialog-title">
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant={"h6"} style={{fontWeight: 700, fontSize: "24px"}}>Neuigkeiten</Typography>
                        <div>
                            <IconButton onClick={(e) => setIsFullscreen(!isFullscreen)} aria-label="delete">
                                {isFullscreen ? <FullscreenExit/> : <Fullscreen/>}
                            </IconButton>
                            <IconButton onClick={(e) => props.setIsOpen(false)} aria-label="delete">
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>

                {!isLoaded &&
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress color={"primary"} />
                    </Box>}

                {isLoaded &&
                    <DialogContent>
                        {news === null ? <></>
                            :
                            <div className={"news-dialog-content"}>
                                {news[selectedPage].map((item, key) =>
                                    <Accordion key={`${Math.random()}`} style={{backgroundColor: "rgba(0,0,0,0.0)", boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)', marginBottom: "25px"}}>
                                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                                            <Typography variant="h6" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                                <span style={{ display: "flex", alignItems: "center" }}>
                                                    <span style={{ color: `var(${item.color})`, display: "flex", alignItems: "center" }}>
                                                      {item.icon} {item.type}:
                                                    </span>
                                                    &nbsp; {item.title}</span>
                                                <span style={{ textAlign: "right" }}>{convertDatetime(item.datetime)}</span>
                                            </Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            {isFullscreen ? item.fullText : item.text}
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            </div>
                        }
                    </DialogContent>
                }

                <DialogActions>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Pagination
                                count={news === null ? 0 : news.length - 1}
                                page={selectedPage}
                                onChange={handlePageChange}
                                size="large"
                            />
                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog>
        </>
    )

}