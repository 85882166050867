import {
    Box, Button, ButtonGroup, Grid, IconButton, Tooltip, Typography, Switch, FormControlLabel
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {Balance, Euro, Info, Pause, PlayArrow} from "@mui/icons-material";
import {GlobalContext} from "../../GlobalContext";

const lastUpdateTime = (time) => {
    const date = new Date(time);
    date.setHours(date.getHours() - 2);
    return date;
};

export default function BalanceElement(props) {
    const [lastUpdate, setLastUpdate] = useState(lastUpdateTime(props.balance.data[0].time));
    const [isCapitalMode, setIsCapitalMode] = useState(true);
    const [canUpdate, setCanUpdate] = useState(true);
    const [autoUpdate, setAutoUpdate] = useState(false);
    const {updateSnackbarInfo, widthBreakpoint} = useContext(GlobalContext);

    useEffect(() => {
        setLastUpdate(lastUpdateTime(props.balance.data[0].time));
    }, [props.balance]);

    useEffect(() => {
        let interval;
        if (autoUpdate) {
            interval = setInterval(() => {
                props.updateBalance();
                setLastUpdate(lastUpdateTime(new Date()));
            }, 30000);
        }

        return () => clearInterval(interval);
    }, [autoUpdate]);

    const onClick = (e) => {
        e.preventDefault();
        updateSnackbarInfo({msg: "Diese Funktion ist derzeit noch nicht verfügbar.", type: "warning"});
    };

    const handleAutoUpdateToggle = (e) => {
        e.preventDefault();

        if (autoUpdate) {
            setAutoUpdate(false);
            updateSnackbarInfo({msg: "Automatische Aktualisierung deaktiviert.", type: "success"});
        } else {
            const datetime = new Date(props.balance.data[0].time);
            datetime.setHours(datetime.getHours() - 2);
            const currentTime = new Date().getTime();
            const timeDifference = currentTime - datetime.getTime();

            if (timeDifference > 30000) {
                updateSnackbarInfo({msg: "Automatische Aktualisierung aktiviert.", type: "success"});
                props.updateBalance();
                setLastUpdate(lastUpdateTime(new Date()));
            } else {
                const remainingSeconds = Math.round((30000 - timeDifference) / 1000);
                const remainingMinutes = Math.floor(remainingSeconds / 60);
                const remainingTime = remainingMinutes > 0 ? `${remainingMinutes} Minute${remainingMinutes > 1 ? 'n' : ''}` : `${remainingSeconds} Sekunde${remainingSeconds > 1 ? 'n' : ''}`;
                updateSnackbarInfo({msg: `Automatische Aktualisierung aktiviert, deine Daten werden in ${remainingTime} aktualisiert.`, type: "success"});
            }

            setAutoUpdate(true);
        }
    };

    return (
        <Grid item xs={12}>
            {!props.balance.err && (
                <div style={{ marginTop: "10px" }}>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="subtitle1">Zuletzt aktualisiert:  </Typography>
                        <Typography variant="subtitle1">{lastUpdate.toLocaleString()}</Typography>
                    </Box>
                    <hr style={{color: "var(--white)"}}/>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Euro sx={{ color: 'var(--green)', mr: 1, my: 0.5 }} />

                            {isCapitalMode ?
                                <Typography variant="h6" color="textPrimary">
                                    <span className={"hover-underline"} onClick={() => setIsCapitalMode(false)}>Kapital</span>: {props.balance.data[0].equity} Euro
                                </Typography>
                                :
                                <Typography variant="h6" color="textPrimary">
                                    <span className={"hover-underline"} onClick={() => setIsCapitalMode(true)}>Kontostand</span>: {props.balance.data[0].balance} Euro
                                </Typography>
                            }

                        </Box>
                        <Tooltip title={"Die Informationen könnten verzögert angezeigt werden."}>
                            <IconButton>
                                <Info sx={{ color: 'var(--white)' }} />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Balance sx={{ color: 'var(--green)', mr: 1, my: 0.5 }} />

                            <Typography variant="h6" color="textPrimary">Aktuell: {(props.balance.data[0].equity - props.balance.data[0].balance).toFixed(2)} Euro</Typography>

                        </Box>
                        <Tooltip title={"Die Angabe kann je nach Kommission und aktuellem Spread leicht variieren."}>
                            <IconButton>
                                <Info sx={{ color: 'var(--white)' }} />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: "center", marginTop: "25px"}}>
                        <ButtonGroup variant="contained" sx={{ gap: (window.outerWidth>=widthBreakpoint) ? '50px' : "5px" }}>
                            <Button endIcon={autoUpdate ? <PlayArrow/> : <Pause/>} color={autoUpdate ? "success" : "warning"} onClick={handleAutoUpdateToggle}>Live-Update</Button>
                            <Button color={"success"} onClick={onClick}>Einzahlen</Button>
                            <Button color={"secondary"} onClick={onClick}>Auszahlen</Button>
                        </ButtonGroup>
                    </Box>
                </div>
            )}
        </Grid>
    );
}
