// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';

function TradingViewWidget() {
    const container = useRef();

    useEffect(
        () => {
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = `
        {
          "symbols": [
            [
              "FX_IDC:USDEUR|1D|EUR"
            ],
            [
              "FX:GBPUSD|1D|EUR"
            ],
            [
              "OANDA:AUDUSD|1D|EUR"
            ],
            [
              "OANDA:USDCHF|1D|EUR"
            ],
            [
              "SAXO:NZDEUR|1D|EUR"
            ]
          ],
          "chartOnly": false,
          "width": "85%",
          "height": "85%",
          "locale": "de_DE",
          "colorTheme": "dark",
          "autosize": true,
          "showVolume": false,
          "showMA": false,
          "hideDateRanges": false,
          "hideMarketStatus": false,
          "hideSymbolLogo": false,
          "scalePosition": "right",
          "scaleMode": "Normal",
          "fontFamily": "-apple-system, BlinkMacSystemFont, Montserrat, sans-serif",
          "fontSize": "10",
          "noTimeScale": false,
          "valuesTracking": "1",
          "changeMode": "price-and-percent",
          "chartType": "candlesticks",
          "headerFontSize": "medium",
          "borderRadius": "12px",
          "backgroundColor": "rgba(0, 0, 0, 0.5)",
          "widgetFontColor": "#fff",
          "lineType": 0,
          "dateRanges": [
            "1d|60",
            "5d|60",
            "1w|60",
            "1m|60",
            "6m|120",
            "12m|1D"
          ],
          "dateFormat": "dd MMM 'yy",
          "upColor": "#22ab94",
          "downColor": "#f7525f",
          "borderUpColor": "#22ab94",
          "borderDownColor": "#f7525f",
          "wickUpColor": "#22ab94",
          "wickDownColor": "#f7525f"
        }`;
            container.current.appendChild(script);
        },
        []
    );

    return (
        <div className="tradingview-widget-container" ref={container}>
            <div className="tradingview-widget-container__widget"></div>
            <div className="tradingview-widget-copyright"><a href="https://de.tradingview.com/" rel="noopener nofollow" target="_blank"><span style={{color: "var(--blue)"}}>Alle Märkte bei TradingView verfolgen</span></a></div>
        </div>
    );
}

export default memo(TradingViewWidget);
