import {createTheme, ThemeProvider} from "@mui/material";
import {GlobalProvider} from "./GlobalContext";
import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App';

import './assets/css/index.css';
import './assets/css/interface.css';
import './assets/css/fonts.css';
import './assets/css/utils.css';


export const theme = createTheme({
    typography: {
        fontFamily: "Montserrat, sans-serif",
        allVariants: {
            color: '#F8F9FA',
        }
    },
    palette: {
        primary: {
            main: '#F8F9FA',
        },
        secondary: {
            main: '#118ab2',
        },
        error: {
            main: "#c1121f",
        },
        warning:{
            main: "#fb5607"
        },
        success: {
            main: "#06d6a0"
        },
        white:{
            main: "#F8F9FA",
            normal: "#DEE2E6",
            dark: "#6C757D"
        },
        text: {
            primary: '#F8F9FA',
            secondary: '#DEE2E6',
        }
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#F8F9FA',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#F8F9FA',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#F8F9FA',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#000',
                    color: '#ffffff', // Textfarbe des Dialogs
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: '#202020',
                    color: '#ffffff',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: '#202020',
                    color: '#ffffff',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    backgroundColor: '#202020',
                },
            },
        },
    },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <GlobalProvider>
            <App />
        </GlobalProvider>
    </ThemeProvider>
);

